import React from 'react';

export default function Category({
  appProps, presentation, icon, slug
}) {
  return (
    <div className={`category ${decodeURI(window.location.pathname).includes(slug) ? 'active' : ''} `}>
      <a className='flex-box items-center' href={`/products/category/${slug}`}>
        <i className= {`fa-light ${ icon } `}> </i>
        <div>{ presentation }</div>
      </a>
    </div>
  )
}
