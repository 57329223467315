import React from 'react';
import Category from './Category';

export default function SideCategories({
  appProps
}) {
  return (
    <>
      <div className="categories heading">
        { appProps.translations.general.sidebar_categories_title }
      </div>
      <div className="categories-list">
        { appProps.categories.map((category, index) => (
          <Category key={index}
                    appProps={ appProps }
                    presentation={ category.presentation }
                    icon={ category.icon }
                    slug={ category.slug  }

          />
        ))}
      </div>
    </>
  )
}
