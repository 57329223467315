import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"
import cookie from "react-cookies"
import { getTranslatableField } from "./utils"

const marketingCookies  = cookie.load("cookieSettings") && cookie.load("cookieSettings").marketingCookies === true
const statisticsCookies = cookie.load("cookieSettings") && cookie.load("cookieSettings").statisticsCookies === true

export function loadProductListAnalytics(products, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name: [ `${ products.map((product) => `${product.title}`)}`],
      currency: `${appProps.storeCurrency}`
    })
  }
}

export function loadProductViewAnalytics(product, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name:  getTranslatableField(product, 'title', appProps.currentLocale),
      value: product.totalPrice,
      currency: `${appProps.storeCurrency}`
    })
  }
}

export function initiateCheckoutAnalytics(order, event) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('InitiateCheckout', {
      content_name: 'Proceed to Checkout / Side Cart',
      value: `${order.total_value}`,
      items: `${order.line_items.length}`,
      products: `${order && order.line_items.map((p) => {
          if (p.bundle){
            return `${p.bundle.presentation}, price: ${p.bundle.totalPrice}, class_name: ${p.bundle.class_name}`
          } else {
            return `${p.variants[0].product.presentation}, price: ${p.variants[0].product.totalPrice}, class_name:${p.variants[0].product.class_name}`
          }
        }
      )}`,
      orderNo: `${order.number}`,
      currency: `${order.currency}`
    })
  }

  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ga('send', 'event', {
  //     eventCategory: 'Click Checkout',
  //     eventAction: 'Initiate Checkout / Side Cart',
  //     eventLabel: event.target.href
  //   });
  // }
}

export function addToCartAnalytics(product, appProps){
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('AddToCart', {
      content_name: `${ getTranslatableField(product, 'title', appProps.currentLocale) }`,
      content_ids: product.id,
      content_type: "product",
      content_category: `${ product.class_name === "product" ? appProps.translations.cart.single_product : appProps.translations.cart.meal }`,
      value: `${product.totalPrice }`,
      currency: product.currency.iso_code
    })
  }

  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ga('require', 'ec');
  //   ga('ec:addProduct', {
  //     'name': `${ getTranslatableField(product, 'title', appProps.currentLocale) }`,
  //     'category': `${ product.class_name === "product" ? appProps.translations.cart.single_product : appProps.translations.cart.meal }`,
  //     'id': `${product.id}`,
  //     'value': `${ product.totalPrice }`,
  //   });
  //   ga('ec:setAction', 'add');
  //   ga('send', 'event', 'Add to cart', 'click', 'addToCart');
  // }
}

export function cartViewAnalytics(order) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      content_ids: order.id,
      content_type: 'product_group',
      total_products: `${order.line_items.length}`,
      products: order && order.line_items.map((item) => {
        if (item.bundle === null ) {
          return `${item.variants[0].product.presentation}, price: ${item.variants[0].product.price}, Total_price:${item.variants[0].product.totalPrice}`
        } else {
        return `${item.bundle.presentation}, price:${item.price}, total: ${item.total}`
        }
      }),
      subtotal: order.subtotal_value,
      vat_total: order.vat_total_value_with_included_taxes,
      total_order: order.total_value
    })
  }
}

export function purchaseAnalytics(order) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom( 'Purchase', {
      content_ids: order.number,
      content_type: 'product_group',
      contents: [`${order && order.line_items.map( (x) => {
        if (x.bundle === null ) {
          return `id: ${x.variants[0].product.presentation}, quantity: ${x.variants.length}`
        } else {
          return `id: ${ x.bundle.presentation}, quantity: ${x.variants.length}`
        }
      })}`],
      num_items: `${order.line_items.length}`,
      value: order.total_value,
      currency: order.currency,
    })
  }

  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ga('require', 'ecommerce');
  //   ga('ecommerce:addTransaction', {
  //     'id': order.number,
  //     'revenue': order.total_value,
  //     'tax': order.vat_total_value_with_included_taxes,
  //   });
  //   order && order.line_items.map((item) => {
  //     if (item.bundle === null ) {
  //       return ga('ecommerce:addItem', {
  //         'id': order.number,
  //         'name': item.variants[0].product.presentation,
  //         'price': item.variants[0].product.totalPrice,
  //       });
  //     } else {
  //       return ga('ecommerce:addItem', {
  //         'id': order.number,
  //         'name': item.bundle.presentation,
  //         'price': item.bundle.totalPrice,
  //       });
  //     }
  //   })
  //   ga('ecommerce:send');
  //   ga('ecommerce:clear');
  // }
}

export function deliveryMethodAnalytics(method, selectedShippingMethod){
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ga('send', {
  //     hitType: 'event',
  //     eventCategory: 'Checkout',
  //     eventAction: 'Selected shipping method',
  //     eventLabel: method || selectedShippingMethod
  //   })
  // }
}

export function paymentMethodAnalytics( presentation ) {
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ga('send', {
  //     hitType: 'event',
  //     eventCategory: 'Checkout',
  //     eventAction: 'Selected payment method',
  //     eventLabel: presentation
  //   })
  // }
}
