import React, { useEffect } from "react"
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import Price from "./Price"
import SalesBadge from "./SalesBadge"
import AddToCart from "./AddToCart"
import ShortDescription from "./ShortDescription"

import { loadProductViewAnalytics } from '../../helpers/analytics'

export default function ProductBurger({
  appProps, product, toggleBurger, csrfToken
}) {
  let productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  useEffect(() => {
    loadProductViewAnalytics(product, appProps)
  }, [])

  return (
    <div className="single-product-burger">

      <div className="product-section">
        { product.images.length > 0 &&
          <div className="image-container">
            <SliderImages
              images={ productImages }
              showThumbnails={ false }
              showFullscreenButton={ false }
              showNav={ false }
              showBullets={ false }
              lightbox={ false }
            />
          </div>
        }

        <div className="details-container">
          <h1 className="product-title">
            { product.presentation }
          </h1>

          <ShortDescription
            product={ product }
          />

          { product.ingredients &&
            <h6 className="product-ingredients">
              { product.ingredients }
            </h6>
          }

          <div className="product-price">
            <h4>
              <Price product={ product } appProps={ appProps } />
              { product.onSale && <SalesBadge discountRate={ product.discountRate } /> }
            </h4>
          </div>

          <AddToCart
            appProps={ appProps }
            product={ product }
            postUrl={'/populate'}
            csrfToken={ csrfToken }
            toggleBurger={ toggleBurger }
          />
        </div>
      </div>

    </div>
  )
}
