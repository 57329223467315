import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import OrderHistoryItem from "../shared/OrderHistoryItem"

export default function Orders({
  appProps, pageName, pageTitle, listOrdersPath
}) {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkFetched, setCheckFetched] = useState(false)

  useEffect( () => {
    fetchOrders(1)
  }, [])

  function fetchOrders(page) {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: `${listOrdersPath}?page=${page}`,
      dataType: "json",
      success: res => {
        setPage(page)
        setTotalPages(res.totalPages)
        setOrders(res.orders)
        setLoading(false)
        setCheckFetched(true)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function renderOrders() {
    return (
      <>
        <div className="heading">
          <h3>{appProps.translations.account.order_history}</h3>
          <h5>{appProps.translations.account.order_history_subtitle}</h5>
        </div>

        <div className="order_history_wrapper">
          <div className="history_headers">
            <div className="row date">
              <h6>{ appProps.translations.orders.date }</h6>
            </div>
            <div className="row number">
              <h6>{ appProps.translations.orders.order_number }</h6>
            </div>
            <div className="row total">
              <h6>{ appProps.translations.orders.order_total }</h6>
            </div>
            <div className="row delivered">
              <h6>{ appProps.translations.orders.order_status }</h6>
            </div>
            <div className="row actions">
              {/* Rendering view icon in this column without heading */}
            </div>
          </div>

          { orders.map((order, index) => (
            <OrderHistoryItem
              key={order.id}
              order={order}
              appProps={appProps}
            />
          ))}

          { !loading && (page < totalPages) &&
            <div className="flex-box item-center content-center" style={{marginTop: 40}}>
              <button className="button" onClick={() => {
                fetchOrders(page + 1)
              }}>
                { appProps.translations.orders.load_more }
              </button>
            </div>
          }
        </div>
      </>
    )
  }

  let noOrdersView = null
  if(checkFetched) {
    noOrdersView =
      <div className="no_data orders_icon">
        <h2>
          { appProps.translations.orders.no_orders_message }
        </h2>
      </div>
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      <div className='card'>
        { orders.length > 0 ? renderOrders() : noOrdersView }

        { loading && <Loader size='medium' position='center' /> }
      </div>
    </>
  )
}
