import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

export default function Coupons({ appProps, orderTotal, couponAdjustments, hideActions, setLoading }) {
  const [coupons, setCoupons] = useState(couponAdjustments)
  const [inputText, setInputText] = useState('')
  const [newTotal, setNewTotal] = useState(orderTotal)

  useEffect( () => {
    setCoupons(couponAdjustments)
    setNewTotal(orderTotal)
  }, [couponAdjustments, orderTotal])

  function applyCoupon() {
    if (inputText.trim().length) {
      setLoading(true)
      let fd = new FormData()
      fd.append('coupon_code', inputText)

      Rails.ajax({
        type: 'POST',
        url: '/checkout/apply_coupon',
        dataType: 'json',
        data: fd,
        success: res => {
          if (res.message.type === 'success') {
            appProps.flashMessage.show(res.message.value, 'success')
          } else {
            appProps.flashMessage.show(res.message.value, 'error')
          }

          if (res.applied) {
            setCoupons( prevCoupons => [...prevCoupons, res.coupon])
            setNewTotal(res.newTotal)
            setInputText('')
          }
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        }
      })
    }
  }

  function removeCoupon(orderAdjustmentId) {
    setLoading(true)
    let fd = new FormData()
    fd.append('order_adjustment_id', orderAdjustmentId)

    Rails.ajax({
      type: 'POST',
      url: '/checkout/remove_coupon',
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.coupons) {
          setCoupons(res.coupons)
          setNewTotal(res.newTotal)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function renderAppliedCoupons() {
    return coupons.map( coupon => (
      <div key={ coupon.orderAdjustmentId } className='row'>
        <div className='heading'>
          { coupon.code }:
        </div>
        <div className='value'>
          { `-${ coupon.discount }` }
        </div>
        { !hideActions &&
          <i className='remove_icon icon' onClick={ () => removeCoupon(coupon.orderAdjustmentId) }></i>
        }
      </div>
    ))
  }

  return (
    <div>
      { (!hideActions || (hideActions && coupons.length > 0)) &&
        <React.Fragment>
          { appProps.translations.checkout.coupons_title }
        </React.Fragment>
      }

      <div className='cart-totals-wrapper'>
        <div className='cart-totals'>
          <div className='cart-contents'>
            { renderAppliedCoupons() }

            { coupons.length > 0 &&
              <div className='row'>
                <div className='heading total'>
                  { `${ appProps.translations.checkout.coupons_total }:` }
                </div>
                <div className='value total'>
                  { newTotal }
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      { !hideActions &&
        <div className='coupons-input-wrapper'>
          <div className='section-heading'>
            <div className='heading-text'>
              { appProps.translations.checkout.coupons_description }
            </div>
          </div>
          <div className='coupons-fields'>
            <input
              type='text'
              placeholder={ appProps.translations.checkout.coupons_placeholder }
              className='input-form coupon-input'
              value={ inputText }
              onChange={ event => setInputText(event.target.value) }
            />

            <button
              type='button'
              className='button'
              onClick={ () => applyCoupon() }
            >
              { appProps.translations.checkout.coupons_apply }
            </button>
          </div>
        </div>
      }
    </div>
  )
}
