import React from 'react';
import Category from './Category';
import ChooseOrderingMethod  from '../header/ChooseOrderingMethod'

export default function TopCategories({ appProps, orderingMethod }) {
  const showCategories = appProps.categoriesPosition === 'header' || appProps.categoriesPosition === 'header_and_list'

  return (
    <>
      { showCategories &&
        <div className="top_categories_wrapper">
          <div className="categories header main-container">
            <div className="categories-list">
              { appProps.categories.map((category, index) => (
                <Category key={index}
                          appProps={ appProps }
                          presentation={ category.presentation }
                          icon={ category.icon }
                          slug={ category.slug }
                />
              ))}
            </div>
          </div>
        </div>
      }
      <ChooseOrderingMethod
        appProps={ appProps}
        orderingMethod={ orderingMethod }
      />
    </>
  )
}
