import React from "react"

export default function CartTotals({
  appProps, order
}) {

  return (
    <div className="cart-totals-wrapper">
      <div className="cart-totals">
        <div className="section-heading">
          <div className="heading-text">
            { appProps.translations.cart.cart_totals }
          </div>
        </div>

        <div className="cart-contents">
          <div className="row">
            <div className="heading">{ appProps.translations.cart.subtotal }</div>
            <div className="value">{ order.subtotal }</div>
          </div>

          <div className="row">
            <div className="heading">{ appProps.translations.cart.vat }</div>
            <div className="value">{ order.vat_total_with_included_taxes }</div>
          </div>

          { order.handling_fee &&
            <div className="row">
              <div className="heading">{ appProps.translations.cart.handling_fee }</div>
              <div className="value">{ order.handling_fee }</div>
            </div>
          }

          { order.delivery_fee &&
            <div className="row">
              <div className="heading">{ appProps.translations.cart.delivery_cost }</div>
              <div className="value">{ order.delivery_fee }</div>
            </div>
          }

          <div className="row">
            <div className="heading total">{ appProps.translations.cart.total }</div>
            <div className="value total">{ order.total }</div>
          </div>
        </div>
      </div>
    </div>
  )
}
