import React from 'react'
import Logo from '@reactiveonline/frontend_shared_components/components/layout/shared/Logo'

export default function Footer({ appProps }) {
  return (
    <div className='header_wrapper'>
      <div className='header_row main-container header_v1'>
        { appProps.preferences.logo ?
          <Logo
            appProps={ appProps }
            target={ '_self' }
            classNames={ 'burger-logo' }
            logo= { appProps.preferences.logo }
          /> :
          <div className='site-name-logo'>
            <a href={ appProps.rootPath } rel='nofollow noindex'>
              { appProps.preferences.site_name ? appProps.preferences.site_name : '' }
            </a>
          </div>
        }
      </div>
    </div>
  )
}
