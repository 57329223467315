import React, { useState, useEffect, useMemo } from "react"

export default function CurrentOrder({
  appProps, currentOrder, lineItems, currency, deleteLineItem
}) {
  const [showLineItems, setShowLineItems] = useState(false)
  const totalValue = useMemo(() => {
    let orderTotal = lineItems.reduce((total, li) => {
      return total += li.totalValue ? parseFloat(li.totalValue) : 0
    }, 0.0)
    return orderTotal ? parseFloat(orderTotal).toFixed(2) : 0
  }, [lineItems])

  return (
    <>
      <div className='main-container' style={{ marginTop: 30 }}>
        <div className='card qr-ordering'>
          <div className='table-wrapper'>
            <div className='pointer flex-box content-space-between items-center' onClick={ ()=> lineItems.length > 0 ? setShowLineItems(prevState => !prevState) : {} }>
              <div className='heading' style={{ fontWeight: 700 }}>
                { appProps.translations.orders.order_status } ({`${currency} ${ totalValue}`})
              </div>
              { lineItems.length > 0 &&
                <div className={`${showLineItems ? 'up_icon' : 'down_icon'}`}></div>
              }
            </div>
            { showLineItems &&
              <>
                <div className='flex-box flex-column order-information animated fadeIn'>
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.orders.order_number }:</div>
                    <div>{ currentOrder.number }</div>
                  </div>
                  <div className='flex-box items-center content-space-between' style={{ marginTop: 5 }}>
                    <div>{ appProps.translations.orders.order_total }:</div>
                    <div>{ `${ currency }${ totalValue }` }</div>
                  </div>
                </div>
                { lineItems.length > 0 &&
                  <div className='table line-items'>
                    <div className='table-head'>
                      <div className='table-cell flex-box items-center content-space-between'>
                        <div>{ appProps.translations.cart.single_product }</div>
                        <div className="flex-box items-center content-space-between">
                          <div>{ appProps.translations.cart.total }</div>
                          <div className="button plain" style={{ opacity: 0, cursor: 'default' }}>
                            <i className="fa-light fa-trash"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='table-body'>
                      { lineItems.map( (item, index)=> {
                        return (
                          <div key={ index } className='table-cell flex-box items-center content-space-between'>
                            <div className="flex-box flex-column">
                              <div>{ item.productTitle }</div>
                              {/*<div>{ item.selectedOptionsTexts }</div>*/}
                            </div>
                            <div className="flex-box items-center content-space-between">
                              <div>{ `x${ item.quantity } ${ currency }${ item.totalValue ? parseFloat(item.totalValue).toFixed(2) : 0 }` }</div>
                              <div className="button plain" onClick={ () => deleteLineItem(item.uid) } style={{ opacity: item.uid.toString().startsWith('nli') ? 1 : 0, cursor: item.uid.toString().startsWith('nli') ? 'pointer' : 'default' }}>
                                <i className="fa-light fa-trash"/>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}
