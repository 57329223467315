import React from 'react'

export default class Product extends React.Component {
  render(){
    return (
      <div>
        <h6>
          {'--'.repeat(this.props.level)}>
          {this.props.presentation}
        </h6>
        {this.props.option_types && this.props.option_types.map((option_type, index) => (
          <OptionType key={index} 
                      presentation={option_type.presentation} 
                      options={option_type.options}

                      level={this.props.level + 1} />
         ))
        }
      </div>
    )
  }
}