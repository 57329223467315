import React from "react"
import Rails from "rails-ujs"
import Button from "../Button"

export default class SideOptions extends React.Component {
  constructor(props) {
    super(props)

    this.handleChecked = this.handleChecked.bind(this)

    const optionTypes = this.initialOptionsState(this.props.product.option_types || [])

    this.state = {
      optionTypes: optionTypes,
      extraItemsPrice: this.getExtraItemsPrice(optionTypes),
      totalValue: this.props.product.totalPrice
    }
  }

  componentDidMount() {
    if(this.props.product.option_types) {
      this.props.onChangedTotal(this.state.extraItemsPrice, this.getSelectedOptions())
    }
    else {
      this.getProductOptionTypes()
    }
  }

  getProductOptionTypes(){
    if (this.props.setLoading) {
      this.props.setLoading(true)
    }
    Rails.ajax({
      url: `/products/${this.props.product.id}/get_product_option_types`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        const optionTypes = this.initialOptionsState(res.option_types)
        const extraItemsPrice = this.getExtraItemsPrice(optionTypes)

        this.setState({
          optionTypes: optionTypes,
          extraItemsPrice: extraItemsPrice
        }, () => { this.props.onChangedTotal(extraItemsPrice, this.getSelectedOptions()) })

        if (this.props.setLoading) {
          this.props.setLoading(false)
        }
      }
    })
  }

  initialOptionsState(optionTypesArray) {
    const { lineItemSelectedOptions } = this.props

    return optionTypesArray.map(optionType => {
      return {
        id: optionType.id,
        isRequired: optionType.is_required,
        maxSelectableOptions: optionType.max_selectable_options,
        multipleSelection: optionType.multiple_selection,
        title: optionType.title,
        images: optionType.images,
        optionables: optionType.optionables.map((optionable, index) => {
          if(optionable.type === "option") {
            let isChecked = false, isDisabled = false
            if((optionType.multiple_selection && optionable.preselected) ||
               (optionType.multiple_selection && optionType.is_required && optionType.optionables.filter(opt => opt.preselected).length === 0 && index === 0) ||
               (!optionType.multiple_selection && index === 0)) {
              isChecked = true
            }

            let hasBeenChecked = false
            if(lineItemSelectedOptions) {
              let subOptSelected = lineItemSelectedOptions.filter(s => s.id == optionType.id)[0]

              if(subOptSelected) {
                if((optionType.multiple_selection && subOptSelected.options.indexOf(optionable.id) > -1) || subOptSelected.optId == optionable.id) {
                  hasBeenChecked = true
                }
              }
            }

            if(!isChecked && optionType.multiple_selection && optionType.max_selectable_options && optionType.optionables.filter(opt => opt.preselected).length >= optionType.max_selectable_options) {
              isDisabled = true
            }

            return {
              id: optionable.id,
              isChecked: lineItemSelectedOptions ? hasBeenChecked : isChecked,
              disabled: isDisabled,
              title: optionable.title,
              preselected: optionType.multiple_selection && optionable.preselected,
              displayPrice: optionable.displayTotalPrice,
              price: optionable.totalPrice
            }
          } else {
            let subOptTypeIsChecked = false, subOptSelected = null
            if(lineItemSelectedOptions) {
              subOptSelected = lineItemSelectedOptions.filter(s => s.id == optionType.id && s.optTypeId == optionable.id)[0]
              if(subOptSelected) {
                subOptTypeIsChecked = true
              }
            }

            return {
              id: optionable.id,
              isChecked: lineItemSelectedOptions ? subOptTypeIsChecked : index === 0,
              title: optionable.title,
              subOptionables: optionable.optionables.map((subOptionable, subIndex) => {
                let subOptIsChecked = false
                if(subOptSelected && subOptSelected.optId == subOptionable.id) {
                  subOptIsChecked = true
                }
                return {
                  id: subOptionable.id,
                  isChecked: lineItemSelectedOptions ? subOptIsChecked : subIndex === 0,
                  title: subOptionable.title,
                  displayPrice: subOptionable.displayTotalPrice,
                  price: subOptionable.totalPrice
                }
              })
            }
          }
        })
      }
    })
  }

  handleChecked(optionTypeId, optionableId, subOptionableId = null) {
    let tempOptionTypes= this.state.optionTypes
    let selectedOptionType = tempOptionTypes.filter(optionType => optionType.id === optionTypeId)[0]
    let selectedOptionable = selectedOptionType.optionables.filter(optionable => optionable.id === optionableId)[0]

    if(subOptionableId) {
      let selectedSubOptionable = selectedOptionable.subOptionables.filter(subOptionable => subOptionable.id === subOptionableId)[0]
      selectedOptionable.subOptionables.map(subOptionable => {
        subOptionable.isChecked = false
      })
      selectedSubOptionable.isChecked = !selectedSubOptionable.isChecked
    } else {
      if(selectedOptionType.multipleSelection) {

        if(selectedOptionType.maxSelectableOptions && selectedOptionType.isRequired) {
          if(!selectedOptionable.isChecked) {
            let checkedOptions = selectedOptionType.optionables.filter(optionable => optionable.isChecked)
            let remainingToCheck = selectedOptionType.maxSelectableOptions - checkedOptions.length
            if(remainingToCheck > 1) {
              selectedOptionable.isChecked = !selectedOptionable.isChecked
            } else if(remainingToCheck === 1) {
              selectedOptionable.isChecked = !selectedOptionable.isChecked
              selectedOptionType.optionables.filter(optionable => !optionable.isChecked).map(optionable => {
                optionable.disabled = true
              })
            }
          } else if(selectedOptionType.optionables.filter(optionable => optionable.isChecked).length > 1) {
            selectedOptionable.isChecked = !selectedOptionable.isChecked
            selectedOptionType.optionables.filter(optionable => optionable.disabled).map(optionable => {
              optionable.disabled = false
            })
          }
        } else if(selectedOptionType.maxSelectableOptions) {
          if(!selectedOptionable.isChecked) {
            let checkedOptions = selectedOptionType.optionables.filter(optionable => optionable.isChecked)
            let remainingToCheck = selectedOptionType.maxSelectableOptions - checkedOptions.length
            if(remainingToCheck > 1) {
              selectedOptionable.isChecked = !selectedOptionable.isChecked
            } else if(remainingToCheck === 1) {
              selectedOptionable.isChecked = !selectedOptionable.isChecked
              selectedOptionType.optionables.filter(optionable => !optionable.isChecked).map(optionable => {
                optionable.disabled = true
              })
            }
          } else {
            selectedOptionable.isChecked = !selectedOptionable.isChecked
            selectedOptionType.optionables.filter(optionable => optionable.disabled).map(optionable => {
              optionable.disabled = false
            })
          }
        } else {
          if(selectedOptionType.optionables.filter(optionable => optionable.isChecked).length > 1) {
            selectedOptionable.isChecked = !selectedOptionable.isChecked
            selectedOptionType.optionables.filter(optionable => optionable.disabled).map(optionable => {
              optionable.disabled = false
            })
          } else {
            if(!selectedOptionType.isRequired || !selectedOptionable.isChecked) {
              selectedOptionable.isChecked = !selectedOptionable.isChecked
            }
          }
        }
      } else {
        selectedOptionType.optionables.map(optionable => {
          optionable.isChecked = false
        })
        if(selectedOptionable.subOptionables) {
          selectedOptionable.subOptionables.map((subOptionable, index) => {
            subOptionable.isChecked = index === 0
          })
        }
        selectedOptionable.isChecked = !selectedOptionable.isChecked
      }
    }

    const extraItemsPrice = this.getExtraItemsPrice(tempOptionTypes)

    if(this.props.onChangedTotal) {
      this.props.onChangedTotal(extraItemsPrice, this.getSelectedOptions(tempOptionTypes))
    }

    this.setState({
      optionTypes: tempOptionTypes,
      extraItemsPrice: extraItemsPrice
    })
  }

  getExtraItemsPrice(optionTypes) {
    let extraItemsPrice = 0
    optionTypes.map(optionType => {
      optionType.optionables.map(optionable => {
        if(optionable.isChecked && optionable.subOptionables) {
          let checkedSubOptionable = optionable.subOptionables.filter(subOptionable => subOptionable.isChecked)[0]
          if(checkedSubOptionable && checkedSubOptionable.price > 0) {
            extraItemsPrice += checkedSubOptionable.price
          }
        } else if(optionable.isChecked && !optionable.preselected && optionable.price > 0) {
          extraItemsPrice += optionable.price
        }
      })
    })

    return extraItemsPrice
  }

  getSelectedOptions(currentStateOptionTypes = null) {
    let optionTypes = currentStateOptionTypes || this.state.optionTypes
    return optionTypes.map((optionType, index) => {
      let obj = {
        id: optionType.id
      }

      if(optionType.multipleSelection) {
        obj.options = optionType.optionables.filter(optionable => optionable.isChecked).map(optionable => optionable.id)
      } else {

        let checkedOptionable = optionType.optionables.filter(optionable => optionable.isChecked)[0]
        if(checkedOptionable.subOptionables) {
          let checkedSubOptionable = checkedOptionable.subOptionables.filter(subOptionable => subOptionable.isChecked)[0]
          obj.optTypeId = checkedOptionable.id
          obj.optId = checkedSubOptionable.id
        } else {
          obj.optId = checkedOptionable.id
        }
      }

      return obj
    })
  }

  renderOptionables(optionType) {
    return (
      <div className="optionables">
        {
          optionType.optionables.map((optionable) => {
            const checkboxClass = optionable.isChecked ? "label-choice checked" : "label-choice"
            const radioClass = optionable.isChecked ? "label-choice-alter checked" : "label-choice-alter"
            const price = !optionable.preselected && optionable.price > 0 && <div className="menu-item-choice-price">{ optionable.displayPrice }</div>

            return (
              <div className="optionable" key={ optionable.id }>
                <div  className={ `option ${optionable.disabled ? "disabled" : "" }` }>
                  <div className={ optionType.multipleSelection ? checkboxClass : radioClass } onClick={() => {
                    this.handleChecked(optionType.id, optionable.id)
                  }}>
                    { optionable.title }
                  </div>
                  { price }
                </div>
                { optionable.isChecked && optionable.subOptionables && this.renderSubOptionables(optionType, optionable) }
              </div>
            )
          })
        }
      </div>
    )
  }

  renderSubOptionables(optionType, optionable) {
    return (
      <div className="sub-group">
        {
          optionable.subOptionables.map((subOptionable) => {
            const price = subOptionable.price > 0 && <div className="menu-item-choice-price">{ subOptionable.displayPrice }</div>

            return (
              <div key={ subOptionable.id } className="sub-group-options">
                <div className={ subOptionable.isChecked ? "label-choice-alter checked" : "label-choice-alter" } onClick={() => {
                  this.handleChecked(optionType.id, optionable.id, subOptionable.id)
                }}>
                  { subOptionable.title }
                </div>
                { price }
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { translations, product, csrfToken, modelName } = this.props

    const optionTypes = this.state.optionTypes.map((optionType) => {
      return (
        <div key={ optionType.id } className={`option-type-items ${modelName === 'product' ? 'card' : ''} `}>
          <div className="option-name">
            <h4>{ optionType.title }</h4>
            { optionType.multipleSelection && optionType.maxSelectableOptions > 0 && <span className="max-quantity-notice">{`You can choose up to ${optionType.maxSelectableOptions} options`}</span> }
            { optionType.isRequired && <span className="option-name-required-indicator">*</span> }
          </div>
          { optionType.images.length > 0 &&
            <div className="image-container">
              <img src={ optionType.images[0].source } />
            </div>
          }
          <div className="option-types-wrapper">
            { optionType.optionables && this.renderOptionables(optionType) }
          </div>
        </div>
      )
    })

    return optionTypes
  }
}
