import React from "react"
import Price from "./Price"
import DeleteLineItem from "./DeleteLineItem"
import Quantity from '../shared/Quantity'
import groupBy from "lodash/groupBy"

export default function LineItem({
  appProps, lineItem, csrfToken, disableRemove, location, onDelete, onChangeQuantity,
  disableQuantityChanging, setLoading
}) {

  return (
    <div className="order_rows">
	    <div className={ `row product ${ lineItem.origin === 'automation' ? 'automation' : '' }` }>
        <div className="contents">
          { lineItem.bundle &&
            <div className="variant">
              <div className="variant-title">
                <a href={ `/products/meals/${lineItem.bundle.slug}` } rel="nofollow noindex">
                  { lineItem.bundle.presentation }
                </a>
              </div>
            </div>
          }
          {
            lineItem.variants.map((variant, index) => {
              return (
                <div className="variant" key={index}>
                  <div className="variant-title">
                    { lineItem.bundle ? `• ${variant.product.presentation}` :
                      <a href={ `/products/${variant.product.slug}` } rel="nofollow noindex">
                        { variant.product.presentation }
                      </a>
                    }
                  </div>
                  {
                    variant.selected_options_texts.split('\n').map((options, option_index) => {
                      return (
                        <div className="variant-option" key={option_index}>
                          { options }
                        </div>
                      )
                    })
                  }
                  <div className="product-notes">
                    <b>{ variant.notes }</b>
                  </div>
                </div>
              )
            })
          }
        </div>
	    </div>

      <Quantity
        lineItemId={ lineItem.id }
        quantity={ lineItem.quantity }
        onChange={ onChangeQuantity }
        disableQuantityChanging={ disableQuantityChanging || lineItem.origin === 'automation' }
        setLoading={ setLoading }
        flashMessage={ appProps.flashMessage }
      />

    <Price price={ lineItem.total } appProps={ appProps }/>

      { !disableRemove && lineItem.origin !== 'automation' &&
        <DeleteLineItem
          appProps={ appProps }
          lineItemId={ lineItem.id }
          csrfToken={ csrfToken }
          location={ location }
          onDelete={ onDelete }
          setLoading={ setLoading }
        />
      }
    </div>
  )
}
