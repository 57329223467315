import React from "react"

export default function ProgressLabel({
  appProps, percent
}) {

  let label;

  if (percent === 100) {
    label = // Icon Delivered
    <div className="checkout_progress">
      <div className="checkout_icon fa-light fa-check animated pulse"></div>
      <span className="checkout_status">{ appProps.translations.order_progress.status_six }</span>
    </div>
  }

  else {
    if (percent > 70) {
      label = // Icon En Route
      <div className="checkout_progress">
        <div className="checkout_icon fa-light fa-moped animated pulse"></div>
        <span className="checkout_status">{ appProps.translations.order_progress.status_five }</span>
      </div>
    }

    else {
      if (percent > 50) {
        label = // Icon QA
        <div className="checkout_progress">
          <div className="checkout_icon fa-light fa-certificate animated pulse"></div>
          <span className="checkout_status">{ appProps.translations.order_progress.status_four }</span>
        </div>
      }

      else {
        if (percent > 35) {
          label = // Icon Cooking
          <div className="checkout_progress">
            <div className="checkout_icon fa-light fa-oven animated pulse"></div>
            <span className="checkout_status">{ appProps.translations.order_progress.status_three }</span>
          </div>
        }

        else {
          if (percent > 20) {
            label = // Icon Preparing
            <div className="checkout_progress">
              <div className="checkout_icon fa-light fa-user-chef animated pulse"></div>
              <div className="checkout_status">{ appProps.translations.order_progress.status_two }</div>
            </div>
          }

          else {
            label = // Icon Order Received
            <div className="checkout_progress">
              <div className="checkout_icon fa-light fa-bags-shopping animated pulse"></div>
              <span className="checkout_status">{ appProps.translations.order_progress.status_one }</span>
            </div>
          }
        }
      }
    }
  }

  return (
    <div className="progress_label">
      {label}
    </div>
  )
}
