import React, { useState } from "react"
import { Loader } from '@reactiveonline/frontend_shared_components'

import SalesBadge from "../products/SalesBadge"

import { loadProductListAnalytics } from '../../helpers/analytics'

export default function ProductItem({ appProps, product, openAddProductModal, canOrder }) {
  const [showFullText, setShowFullText] = useState(false)

  const darkBackgroundStyle = { background: '#f7f7f7' }
  const itemBorderStyle = { border: '1px solid #dddddd' }
  let productItemStyle = appProps.darkBackground ? darkBackgroundStyle : { backgroundColor: '#ffffff' }
  productItemStyle = appProps.itemBorder ? { ...productItemStyle, ...itemBorderStyle } : productItemStyle

  function fullText(text) {
    if ( text.length <= 100 || showFullText ) {
      return text.replace(/(<([^>]+)>)/gi, "");
    } else if ( text.length > 100 ) {
      const strippedDescription = text.replace(/(<([^>]+)>)/gi, "");
      return strippedDescription.substring(0, 100) + ' ';
    }
  }

  return (
    <div className={ `${appProps.itemShadow ? 'tiles-box-shadow' : ''}` } style={ productItemStyle, { marginBottom: 20 } }>
      <div className="flex-box product-tile">

        <div className="product-image">
          { product.images.length > 0 &&
            <img src={ product.images[0].thumbnail } />
          }
        </div>

        <div  className='flex-box product-details flex-column'>
          <div className='product-title'>
            { product.presentation }
          </div>
          <div className='price'>
            { product.priceLists.dine_in.displayPrice }
          </div>
          { product.description.length > 1 && product.description != '<p></p>' &&
            <div className="short-description" style={{ display: 'inline-block' }}>
              { fullText(product.description) }
              <span style={{ marginLeft: 5 }} className={`pointer ${product.description && product.description.length > 100 ? (showFullText ? 'up_icon' : 'down_icon') : '' }`} onClick={ ()=> setShowFullText(prevState => !prevState) } />
            </div>
          }
          { canOrder &&
            <div  className='button add-to-cart pointer'
                  style={{ marginTop: 10, width: 'fit-content' }}
                  onClick={ () => openAddProductModal(product) }>
              { appProps.translations.qr_ordering.add_to_order }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
