export function pagingOptions() {
  return [{
    title: '12',
    value: '12'
  }, {
    title: '24',
    value: '24'
  }, {
    title: '48',
    value: '48'
  }]
}
