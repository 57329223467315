import React from "react"
import { slide as Menu } from 'react-burger-menu'
import Categories from './SideCategories'

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "50px",
    height: "50px",
    right: "auto",
    left: "30px",
    top: "0px"
  },
  bmBurgerBars: {
    width: "50px",
    height: "50px",
    opacity: '0',
    background: 'transparent'
  },
  bmMenuWrap: {
    left: "0",
    width: "100%",
    border: "none",
    height: "100%"
  },
  bmOverlay:{
    background: 'rgba(0,0,0,0.7)'
  }
}

export default class Burger extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      menuOpen: false
    }
  }
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }

  closeMenu () {
    this.setState({menuOpen: false})
  }

  render () {
    return (
      <Menu
        crossClassName={ "cross-class" }
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        styles = {styles}
      >
        <Categories
          categories={this.props.categories}
        />
      </Menu>
    );
  }
}
