import React from "react"
import { initiateCheckoutAnalytics } from './../../helpers/analytics'

export default function TopBannerFullWidth({
  appProps, order, infoMessage, linkTitle, linkTo, InfoComponent
}) {
  return (
    <div className="top-banner-full-width animated fadeIn inner-box-shadow">
      <div className="main-container">
        <div className="top-banner-content flex-box items-center">
          { infoMessage &&
            <div className="message">
              { infoMessage }
              { InfoComponent && <InfoComponent { ...this.props.infoComponentProps } /> }
            </div>
          }
          { linkTo &&
            <div className="flex-box button-wrapper content-end">
              <a
                className="button"
                href={ linkTo }
                onClick={(event) => { initiateCheckoutAnalytics(order, event) }}
              >
                { linkTitle }
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
