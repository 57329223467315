import React, { useState, useRef } from "react"
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'
import Rails from "rails-ujs"
import ReactDOM from "react-dom"

export default function Address({
  appProps, updateData, address, userId, disableActions, onCheckoutSelect,
  checkoutAddressSelectedId, csrfToken
}) {
  const [confirmModal, setConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)

  function deleteAddress() {
    setLoading(true)
    Rails.ajax({
      url: `/account/addresses/${address.uid}`,
      type: 'DELETE',
      dataType: 'json',
      success: res => {
        if(res.addresses) {
          updateData({ addresses: res.addresses }, false)
          setConfirmModal(false)
          appProps.flashMessage.show(res.response, 'success')
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
        appProps.flashMessage.show(res.response, 'error')
        setConfirmModal(false)
      }
    })
  }

  return (
    <>
      <div className={`address pointer ${checkoutAddressSelectedId === address.id ? 'selected' : ''}`} onClick={() => {
        if(onCheckoutSelect) {
          onCheckoutSelect(address)
        }
      }}>
        { !disableActions && <button className="button plain remove_icon" onClick={ ()=> setConfirmModal(true) }></button> }
        <div className="address-presentation">
          <p>{ address.full_presentation }</p>
        </div>
      </div>

      { confirmModal &&
        <Modal
          visible
          mode='small'
          elementsClass='content-center'
          icon={ 'exclamation' }
          theme='body-bg'
          closeModal={ ()=> setConfirmModal(false) }
          abortAction={ ()=> setConfirmModal(false) }
          abortText={ Store.translations.cancel }
          acceptAction={ ()=> deleteAddress() }
          saveText={ Store.translations.ok }
        >
          { loading ?
            <Loader position='center items-center content-center' size='medium' />
            :
            <p style={{ textAlign: 'center' }}>{ appProps.translations.addresses.delete_address }</p>
          }
        </Modal>
      }
    </>
  )
}
