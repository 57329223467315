import React from "react"
import { Circle } from "rc-progress"

import ProgressLabel from "./ProgressLabel"

export default function ProgressBar({
  appProps, percent, customClass
}) {
  return (
    <div className={`progress_bar ${customClass}`}>
      <ProgressLabel appProps={ appProps } percent={percent} />
      <Circle percent={percent} strokeWidth="2" strokeColor="#000000" />
    </div>
  )
}
