import React, { useState, useEffect } from 'react'
import ListStyles from "../../products/ListStyles"
import ProductItem from '../../products/ProductItem'
import ProductBurger from '../../products/ProductBurger'

export function ProductList({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [products, setProducts] = useState([])
  const [burgerIsOpened, setBurgerIsOpened] = useState(false)
  const [currentProduct, setCurrentProduct] = useState({})
  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedProductItems !== 'custom' || (settings.selectedProductItems === 'custom' && settings.selectedProducts.length)) {
      setLoading(true)

      Rails.ajax({
        type: 'GET',
        url: `${appProps.selectedProductsPath}?items=${JSON.stringify(settings.selectedProducts)}&selected_product_items=${settings.selectedProductItems}&categories=${JSON.stringify(settings.selectedCategories)}&show_all_info=true&product_limit=${settings.productLimit}`,
        dataType: 'json',
        success: res => {
          setProducts(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  function toggleBurger(openStatus, currentProduct = {}) {
    setBurgerIsOpened(openStatus)
    setCurrentProduct(currentProduct)
	}

  function renderBurger(product) {
    if(!burgerIsOpened) {
      return null
    }

    return (
      <div id="products_modal" className={ `animated ${burgerIsOpened ? "slideInRight" : "slideOutRight"}` } >
        <div className="product-modal-container">
          <div id="close_burger" className="close_icon" onClick={() => toggleBurger(false)}></div>
          <div className="product-modal-inner">
            <div className="single-product-burger">
              <div className="product-section">
                <ProductBurger
                  appProps={ appProps }
                  product={ currentProduct }
                  csrfToken={ '' }
                  toggleBurger={ toggleBurger }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="builder-product-list product-list flex-box flex-column">
      <ListStyles appProps={ appProps } productsPerRow={ settings.productsPerRow } />

      <div className={`products flex-box flex-wrap ${settings.viewType}`}>
        { products.map( (product, index) => (
          <div key={index} className={`product ${ settings.productsPerRow === '1' ? 'list-view' : '' }`}>
            <ProductItem
              appProps={ appProps }
              key={ index }
              product={ product }
              renderBurger={ renderBurger }
              toggleBurger={ toggleBurger }
              isRelated
            />
          </div>
        ))}
      </div>
    </div>
  )
}
