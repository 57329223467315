import React from "react"
import ProductItem from "./ProductItem"

export default function RelatedProducts({
  appProps, related
}) {

  return (
    <ul className="products">
      { related.map((product, index) => (
        <li className='product' key={index}>
          <ProductItem
            appProps={ appProps }
            product={ product }
            isRelated={ true }
            renderBurger={ ()=> {} }
            toggleBurger={ ()=> {} }
          />
        </li>
      ))}
    </ul>
  )
}
