import React, { useState } from "react"

import MiniCart from "./MiniCart"
import { AuthorizableLink, LogoutLink } from '@reactiveonline/frontend_shared_components'

export default function HeaderIcons({ appProps, order, orderingMethod }) {
  const [currentOrder, setCurrentOrder] = useState(order || appProps.order)

  const localePrefix = appProps.currentLocale ? `/${appProps.currentLocale}` : ''
  const { translations, registrationEnabled } = appProps

  let loginData = [{ title: translations.account.login_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` }]
  if(registrationEnabled) {
    loginData.push({ title: translations.account.register_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` })
  }

  return (
    <>
      <div className="header-icons-container">
        <ul>
          { currentOrder &&
            <li className="cart-dropmenu mini_cart" style={{ position: 'relative' }}>
              { window.location.pathname != appProps.checkoutPath &&
                <div id="mini-cart-ajax-container">
                  <MiniCart
                    appProps={ appProps }
                    order={ currentOrder }
                  />
                </div>
              }
            </li>
          }
          { appProps.userFormLoginType ?
            <AuthorizableLink
              appProps={ appProps }
              linkClass="account_icon my-account"
              authorizableScreen='login'
              linkRef={ appProps.accountPath }
            /> :
            <a className="account_icon my-account" href={ appProps.accountPath }></a>
          }
          <li className="logout">
            { appProps.user &&
              <LogoutLink
                logoutPath={ appProps.logoutPath }
                redirectAfterAuthPath={ appProps.redirectAfterAuthPath }
              />
            }
          </li>
        </ul>
      </div>
    </>
  )
}
