import React from "react"

export default function ShortDescription({ product, image, content, csrfToken }) {
  let shortDescription =
    <>
      <div className="short-description">
        <div dangerouslySetInnerHTML={{__html: product.description}} className="text"></div>
      </div>
    </>
      if ( product.description == "<p><br></p>" ) {
        shortDescription =
        <>
        </>
      }
  return ( shortDescription )
}
