import React, { useState, useEffect } from "react"
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'
import Addresses from "../account/screens/Addresses"
import NewAddressModal from "../shared/NewAddressModal"
import cookie from 'react-cookies'

export default function ChooseMethodModal({
  appProps, getStoresPath, user, newAddressPath,
  addressFormFields, googleApiKey, userCoordinates, order,
  setDeliveryPath, setPickupPath
}) {
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(appProps.orderingMethod)
  const [pickupStore, setPickupStore]                       = useState(appProps.currentStore)
  const [selectedAddressId, setSelectedAddressId]           = useState(appProps.selectedAddress && appProps.selectedAddress.id)
  const [stores, setStores]                                 = useState([])
  const [loading, setLoading]                               = useState(false)
  const [addressModalOpen, setAddressModalOpen]             = useState(false)

  useEffect(() => {
    if ((selectedShippingMethod === 'pickup') || ((selectedShippingMethod === 'delivery') && selectedAddressId)) {
      fetchStores()
    }
  }, [selectedShippingMethod, selectedAddressId])

  function fetchStores() {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: `${ getStoresPath }${ selectedAddressId ? '?address_id=' + selectedAddressId : '' }`,
      dataType: "json",
      success: res => {
        setStores(res.stores)
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function selectAddress() {
    setLoading(true)
    let fd = new FormData()
    fd.append('address_id', selectedAddressId)
    fd.append('user_id', appProps.user.id)
    fd.append('store_id', pickupStore.id)
    if (order) {
      fd.append('order_id', order.id)
    }

    Rails.ajax({
      url: setDeliveryPath,
      type: 'POST',
      format: 'json',
      data: fd,
      success: res => {
        cookie.save('orderingMethod', 'delivery', { path: '/' })
        cookie.save('currentAddress', selectedAddressId, { path: '/' })
        cookie.save('currentStore', pickupStore.id, { path: '/' })
        if (res.message) {
          appProps.flashMessage.show(res.message, 'notice')
        }
        window.location.reload()
      },
      error: res => {
        if (res.error) {
          appProps.flashMessage.show(appProps.translations.checkout[res.error], 'error')
        }
        setLoading(false)
      }
    })
  }

  function setCurrentStore() {
    setLoading(true)
    let fd = new FormData()
    fd.append('store_id', pickupStore.id)
    fd.append('user_id', appProps.user.id)
    if (order) {
      fd.append('order_id', order.id)
    }

    Rails.ajax({
      url: setPickupPath,
      type: 'POST',
      format: 'json',
      data: fd,
      success: res => {
        cookie.save('orderingMethod', 'pickup', { path: '/' })
        cookie.save('currentStore', pickupStore.id, { path: '/' })
        if (res.message) {
          appProps.flashMessage.show(res.message, 'notice')
        }
        window.location.reload()
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function acceptAction() {
    if (selectedShippingMethod === 'pickup' && pickupStore) {
      setCurrentStore()
    }
    else if (selectedShippingMethod === 'delivery' && selectedAddressId && pickupStore) {
      selectAddress()
    }
  }

  function updateData(data) {
    appProps.setCurrentAppProps({
      user: {
        ...appProps.user,
        addresses: data.addresses
      }
    })
  }

  function handlePreviousStep() {
    if (pickupStore) {
      setPickupStore(null)
      if (selectedShippingMethod === 'pickup') {
        setSelectedShippingMethod(null)
      } else {
        setSelectedAddressId(null)
      }
    } else if (selectedAddressId) {
      setSelectedAddressId(null)
      setSelectedShippingMethod(null)
    } else {
      setSelectedShippingMethod(null)
    }
  }

  function storeRenderer() {
    return (
      <div className='flex-box flex-column items-start'>
        <div className='heading centered flex-box items-center'>
          <div className='chevron_icon_left pointer' style={{ width: 30 }} onClick={ handlePreviousStep } />
          <div>{ appProps.translations.checkout.inform_about_currently_open_stores }</div>
        </div>

        <div className='flex-box flex-column content-start items-start'>
          <>
            { stores && stores.length > 0 ?
                stores.map( (store, index)=> {
                  return (
                    <div key={ index } className='pickup-store flex-box pointer' onClick={ ()=> setPickupStore(store) }>
                      <div className={`choose-selection ${pickupStore && pickupStore.id === store.id ? 'filter_icon_checkbox_filled' : 'filter_icon_checkbox'} `} />
                      <div className='flex-box flex-column '>
                        <div className='bold'>{ store.presentation }</div>
                        { store.address &&
                          <div className='address-info'>{ store.address.full_presentation }</div>
                        }
                      </div>
                    </div>
                  )
                })
                : <div className='flex-box items-center content-center'>
                    { appProps.translations.checkout.currenty_closed_store }
                  </div>
            }
            { pickupStore && pickupStore.address &&
              <iframe width="100%"
                      style={{ marginTop: 20 }}
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src={`https://maps.google.com/maps?width=300&height=200&hl=en&q=${pickupStore.address.full_presentation}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}>
              </iframe>
            }
          </>
        </div>
      </div>
    )
  }

  return (
    <div>
      { !addressModalOpen &&
        <Modal
          visible={ !addressModalOpen }
          mode='medium'
          closeModal={ null }
          abortAction={ null }
          abortText={ null }
          acceptAction={ !loading && ((selectedShippingMethod === 'pickup' && pickupStore) || (selectedShippingMethod === 'delivery' && selectedAddressId && pickupStore)) ? acceptAction : null }
          saveText={ !loading && ((selectedShippingMethod === 'pickup' && pickupStore) || (selectedShippingMethod === 'delivery' && selectedAddressId && pickupStore)) ? appProps.translations.shipping_method.startOrder : null }
        >
          <div className='choose-method-modal'>
            { !selectedShippingMethod &&
              <div className='flex-box flex-column items-center content-center'>
                <div className='heading centered'>
                  { appProps.translations.shipping_method.chooseShippingMethod }
                </div>
                <div className='flex-box flex-wrap items-center content-center'>
                  <div
                    className='flex-box flex-column items-center content-center pointer method-tile'
                    onClick={ () => setSelectedShippingMethod('delivery') }
                  >
                    <div className='shipping-icon icon' />
                    <div>{ appProps.translations.checkout.delivery_title }</div>
                  </div>
                  <div
                    className='flex-box flex-column items-center content-center pointer method-tile'
                    onClick={ () => setSelectedShippingMethod('pickup') }
                  >
                    <div className='pickup-icon icon' />
                    <div>{ appProps.translations.checkout.pickup_title }</div>
                  </div>
                </div>
              </div>
            }

            { selectedShippingMethod === 'delivery' &&
              <>
                { !loading && !selectedAddressId &&
                  <div className='flex-box flex-column items-start'>
                    <div className='heading centered flex-box items-center'>
                      <div className='chevron_icon_left pointer' style={{ width: 30 }} onClick={ () => setSelectedShippingMethod(null) } />
                      <div>{ appProps.translations.checkout.choose_address }</div>
                    </div>
                    <Addresses
                      appProps={ appProps }
                      user={ user }
                      newAddressPath={ newAddressPath }
                      addressFormFields={ addressFormFields }
                      disableActions={ true }
                      googleApiKey={ googleApiKey }
                      userCoordinates={ userCoordinates }
                      checkoutAddressSelectedId={ selectedAddressId }
                      onCheckoutSelect={ (address) => {
                        setSelectedAddressId(address.id)
                      }}
                      setAddressModalOpen={ setAddressModalOpen }
                    />
                  </div>
                }

                { !loading && !!selectedAddressId && storeRenderer() }
              </>
            }

            { !loading && selectedShippingMethod === 'pickup' && storeRenderer() }

            { loading &&
              <Loader
                position='center flex-1'
                size='large'
              />
            }
          </div>
        </Modal>
      }

      { addressModalOpen &&
        <NewAddressModal
          appProps={ appProps }
          userId={ appProps.user.id }
          postUrl={ newAddressPath }
          addressFormFields={ addressFormFields }
          updateData={ updateData }
          translations={ appProps.translations }
          googleApiKey={ googleApiKey }
          onCheckoutSelect={ (address) => {
            setSelectedAddressId(address.id)
          }}
          userCoordinates={ userCoordinates }
          setModalOpen={ setAddressModalOpen }
        />
      }
    </div>
  )
}
