import React from "react"

export default function ListStyles({ appProps, productsPerRow }) {

  const productItems = productsPerRow ? productsPerRow : appProps.productsPerListLine

  let productListStyles = null
    if ( productItems == 2) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .products .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
          .product-list .products.menu .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
        }
      `}} />
    } else if ( productItems == 3) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .products .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
          .product-list .products.menu .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
        }

        @media screen and (min-width:750px) {
          .product-list .products .product {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    } else if ( productItems == 4) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:410px) {
          .product-list .products .product {
            width: calc( 95% / 2 ) !important;
            margin-right: calc( 5% / 2 ) !important;
          }
          .product-list .products .product:last-child {
            margin-right: 0px !important;
          }
        }

        @media screen and (min-width:600px) {
          .product-list .products.menu .product {
            width: calc( 95% / 2 ) !important;
            margin-right: calc( 5% / 2 ) !important;
          }
        }

        @media screen and (min-width:750px) {
          .product-list .products .product {
            width: calc( 95% / 3 ) !important;
            margin-right: calc( 5% / 3 ) !important;
          }
          .product-list .products .product:last-child {
            margin-right: 0px !important;
          }
        }

        @media screen and (min-width:800px) {
          .product-list .products .product {
            width: calc( 95% / 4 ) !important;
            margin-right: calc( 5% / 4 ) !important;
          }
          .product-list .products .product:last-child {
            margin-right: 0px !important;
          }
        }
      `}} />
    } else if ( productItems == 5) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .products .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
          .product-list .products.menu .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
        }

        @media screen and (min-width:750px) {
          .product-list .products .product {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }

        @media screen and (min-width:800px) {
          .product-list .products .product {
            width: calc( 95% / 4 );
            margin-right: calc( 5% / 4 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }

        @media screen and (min-width:1000px) {
          .product-list .products .product {
            width: calc( 95% / 5 );
            margin-right: calc( 5% / 5 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    } else if ( productItems == 6) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .products .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
          .product-list .products.menu .product {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
        }

        @media screen and (min-width:750px) {
          .product-list .products .product {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }

        @media screen and (min-width:800px) {
          .product-list .products .product {
            width: calc( 95% / 4 );
            margin-right: calc( 5% / 4 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }

        @media screen and (min-width:900px) {
          .product-list .products .product {
            width: calc( 95% / 5 );
            margin-right: calc( 5% / 5 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }

        @media screen and (min-width:1024px) {
          .product-list .products .product {
            width: calc( 95% / 6 );
            margin-right: calc( 5% / 6 );
          }
          .product-list .products .product:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    }

  return ( productListStyles )
}
