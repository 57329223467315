import React, { useEffect } from "react"
import { Content } from '@reactiveonline/frontend_shared_components/builder'
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import Price from "./Price"
import SalesBadge from "./SalesBadge"
import AddToCart from "./AddToCart"
import ShortDescription from "./ShortDescription"
import RelatedProducts from "./RelatedProducts"
import ChooseMethodModal from "./ChooseMethodModal"

import { loadProductViewAnalytics } from '../../helpers/analytics'

export default function ProductView({
  appProps, product, image, content, order, csrfToken, hasContent, relatedProducts,
  getStoresPath, setDeliveryPath, setPickupPath, currentUser,
  newAddressPath, addressFormFields, googleApiKey, userCoordinates
}) {

  let productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  useEffect( () => {
    loadProductViewAnalytics(product, appProps)
  }, [])

  if (!appProps.showChooseMethodModal) {
    return (
      <div className='single-product'>
        <div className="hero-image product_page_version_3">
          <SliderImages
            images={ productImages }
            showThumbnails={ productImages && productImages.length > 1 && true }
            showFullscreenButton={ false }
            showNav={ productImages && productImages.length > 1 && true }
            showBullets={ productImages && productImages.length > 1 && true }
            lightbox={ true }
            thumbnailPosition={ productImages && productImages.length > 1 ? appProps.thumbnailPosition : 'bottom' }
          />
        </div>

        <div className="single-product-wrapper main-container product_page_version_3">

          <div className="product-section">

            <div className="details-container">
              <h1 className="product-title">
                { product.presentation }
              </h1>

              <div className="product-price">
                <h4>
                  <Price
                    appProps={ appProps }
                    product={ product }
                  />
                  { product.onSale &&
                    <SalesBadge
                      discountRate={ product.discountRate }
                    />
                  }
                </h4>
              </div>
            </div>

            <ShortDescription
              product={ product }
            />

            { product.ingredients &&
              <h6 className="product-ingredients">
                { product.ingredients }
              </h6>
            }

            <AddToCart
              appProps={ appProps }
              product={ product }
              postUrl={'/populate'}
              order={ order }
              csrfToken={ csrfToken }
            />
          </div>

        </div>

        { content &&
          <Content
            appProps={ appProps }
            getBuilderContentUrl={ content }
          />
        }

        { relatedProducts.length > 0 && appProps.relatedActive &&
          <div className='related-products-list'>
            <div className="main-container">
              <h2>
                { appProps.translations.products.similars_heading }
              </h2>
            </div>
            <div className="main-container">
              <RelatedProducts
                appProps={ appProps }
                related={ relatedProducts }
                image={ image }
              />
            </div>
          </div>
        }

      </div>
    )
  } else {
    return (
      <ChooseMethodModal
        appProps={ appProps }
        getStoresPath={ getStoresPath }
        newAddressPath={ newAddressPath }
        user={ currentUser }
        order={ order }
        addressFormFields={ addressFormFields }
        googleApiKey={ googleApiKey }
        userCoordinates={ userCoordinates }
        setDeliveryPath={ setDeliveryPath }
        setPickupPath={ setPickupPath }
      />
    )
  }
}
