import React from 'react'
import { BankDetails } from '@reactiveonline/frontend_shared_components'
import Button from '../Button'
import ProgressBar from './ProgressBar'
import CartItems from '../cart/CartItems'
import CartTotals from '../cart/CartTotals'
import Coupons from './Coupons'
import OrderTrackers from './OrderTrackers'

export default function Complete({ appProps, order, store, percent, shippingMethod, paymentMethod, renderTrackers, bankDetails }) {
  return (
    <div className='main-container'>
      <div className="order-complete-wrapper flex-box flex-wrap">
        <div className='column-main'>
          <div className="success card flex-box flex-column">
            <h1>
              <i className="checked_icon" style={{ marginRight: 10 }} />
              { appProps.translations.checkout.complete_heading }
            </h1>
            <div className='subtitle flex-box flex-column'>
              <div>{ appProps.translations.checkout.complete_subheading }</div>
            </div>
            <div className="button-wrapper flex-box">
              <Button
                value={ appProps.translations.checkout.my_account_button }
                className={ 'button' }
                linkTo={ appProps.orderHistoryPath }
              />
            </div>
          </div>

          <div className="cart-wrapper card">
            <div id="cart">
              <CartItems
                appProps={ appProps }
                order={ order }
                disableRemove={ true }
                disableQuantityChanging={ true }
              />

              <CartTotals
                appProps={ appProps }
                order={ order }
              />

              <Coupons
                appProps={ appProps }
                orderTotal={ order.totalWithAdjustments }
                couponAdjustments={ order.couponAdjustments }
                hideActions={ true }
              />
            </div>
          </div>
        </div>

        <div className='column-details'>
          <div className="order-info card">
            { !!store.expectedDeliveryTime && parseInt(store.expectedDeliveryTime) !== 0 &&
              <div className='flex-box flex-column items-center content-center'>
                <div className='subtitle'>
                  { appProps.translations.checkout.track_order }
                </div>
                <ProgressBar
                  appProps={ appProps }
                  percent={ percent }
                  customClass={''}
                />
              </div>
            }
            <div className='flex-box items-center content-space-between' style={{ marginBottom: 10 }}>
              <div className='flex-1'>{appProps.translations.orders.order_number}</div>
              <div className='bold'>#{ order.number }</div>
            </div>
            { !!store.expectedDeliveryTime && parseInt(store.expectedDeliveryTime) !== 0 &&
              <div className='flex-box items-center content-space-between' style={{ marginBottom: 10 }}>
                <div className='flex-1'>{ appProps.translations.checkout.expected_delivery_time }</div>
                <div className='bold'>{ store.expectedDeliveryTime }</div>
              </div>
            }
            <div className='flex-box items-center content-space-between' style={{ marginBottom: 10 }}>
              <div className='flex-1'>{ appProps.translations.checkout.delivery_method }</div>
              <div className='bold'>{ shippingMethod }</div>
            </div>
            <div className='flex-box items-center content-space-between' style={{ marginBottom: 10 }}>
              <div className='flex-1'>{ appProps.translations.checkout.payment_method }</div>
              <div className='bold'>{ paymentMethod }</div>
            </div>
            { !!bankDetails.length &&
              <BankDetails
                appProps={ appProps }
                bankDetails={ bankDetails }
              />
            }
            <div className='flex-box flex-column' style={{ marginBottom: 10 }}>
              <div className='flex-1'>{ appProps.translations.addresses.delivery_address }</div>
              <div className='bold'>{ order.delivery_address.full_presentation }</div>
            </div>
            { order.special_instructions &&
            <div className='flex-box flex-column' style={{ marginBottom: 10 }}>
              <div className='flex-1'>{ appProps.translations.checkout.order_notes }</div>
              <div className='bold'>{ order.special_instructions }</div>
            </div>
            }
          </div>
        </div>
      </div>

      <OrderTrackers
        appProps={ appProps }
        renderTrackers={ renderTrackers }
        order={ order }
      />
    </div>
  )
}
