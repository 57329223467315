import React from 'react'

export default function Quantity({
  lineItemId, onChange, setLoading, quantity, disableQuantityChanging, flashMessage
}) {

  function onChangeQuantity(quantity, added = false) {
    if (setLoading) setLoading(true)

    if (quantity > 0) {
      if (lineItemId) {
        Rails.ajax({
          url: `/line_items/${ lineItemId }/change_quantity?quantity=${ quantity }&added=${added}`,
          type: 'POST',
          dataType: 'json',
          success: res => {
            if (onChange) {
              onChange(res.order)
            }
            if (setLoading) setLoading(false)
          },
          error: res => {
            if (setLoading) setLoading(false)
            flashMessage.show(res.message, 'error')
          }
        })

      } else {
        if (setLoading) setLoading(false)
        onChange(quantity)
      }
    }
  }

  return (
    <div className="quantity-wrapper">
      { disableQuantityChanging ?
        <div>{ quantity > 1 ? `x${ quantity }` : '' }</div>
        :
        <React.Fragment>
          <i className='minus_icon button' onClick={ () => onChangeQuantity(quantity - 1) }/>
          <input className="button plain" type='text' name={ `line_item[quantity]` } value={ quantity } readOnly/>
          <i className='plus_icon button' onClick={ () => onChangeQuantity(quantity + 1, true) }/>
        </React.Fragment>
      }
    </div>
  )
}
