import React from "react"

export default function Price({ appProps, product }) {
  let displayPrice =
    <div className="price-wrapper">
      <h4>
        { product.displayTotalPrice }
      </h4>
      { !product.appliedTax.includedInPrice && product.appliedTax.rate > 0 &&
        <div className="tax-amount">
          <h4>
            { `(${ appProps.translations.products.includes } ${ appProps.translations.cart.vat } ${ Math.round(product.appliedTax.rate * 100) }%)` }
          </h4>
        </div>
      }
    </div>

  return displayPrice
}
