import React, { useState, useEffect, useMemo } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SalesBadge from "../products/SalesBadge"
import Header from './Header'
import Footer from './Footer'
import ProductItem from './ProductItem'

import { loadProductListAnalytics } from '../../helpers/analytics'

export default function QrMenu({ appProps, categories, products, bundles, containerClass }) {
  const [currentCategory, setCurrentCategory] = useState(categories[0])
  const [tabIndex, setTabIndex]               = useState(0)

  useEffect(() => {
    loadProductListAnalytics(products, appProps)
  }, [])

  const categoryItems = useMemo(() => {
    if (currentCategory) {
      return [
        ...products.filter(p => p.categoryIds.includes(currentCategory.id)),
        ...bundles.filter(b => b.categoryIds.includes(currentCategory.id))
      ]
    }
    return []
  }, [currentCategory])

  const darkBackgroundStyle = { background: '#f7f7f7' }
  const itemBorderStyle = { border: '1px solid #dddddd' }

  let productItemStyle = appProps.darkBackground ? darkBackgroundStyle : { backgroundColor: '#ffffff' }
  productItemStyle = appProps.itemBorder ? { ...productItemStyle, ...itemBorderStyle } : productItemStyle

  return (
    <>
      <Header appProps={ appProps } />
      <div className='main-container'>
        <div className={ containerClass }>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            { categories.length > 0 &&
              <TabList>
                { categories.map( (cat, index)=> {
                  return (
                    <Tab  className={` ${ index === tabIndex ? 'button' : 'button inverted'} `}
                          style={{ display: 'inline-block', marginRight: 10 }}
                          key={ cat.apiUid }
                          onClick={ ()=> setCurrentCategory(cat) }>
                          { cat.presentation }
                    </Tab>
                  )
                })}
              </TabList>
            }

            { categories.map( (cat, index)=> {
              return (
                <TabPanel key={index} style={{ marginTop: 30 }}>
                  <div className="flex-box flex-column">
                    { categoryItems.map( (product, index)=>{
                      return (
                        <ProductItem
                          key={ index }
                          appProps={ appProps }
                          product={ product }
                          canOrder={ false }
                        />
                      )
                    })}
                  </div>
                </TabPanel>
              )
            })}
          </Tabs>
        </div>
      </div>
    </>
  )
}
