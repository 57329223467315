import React, { useEffect } from "react"
import CustomSelect from "../shared/CustomSelect"
import { deliveryMethodAnalytics } from '../../helpers/analytics'

export default function ShippingMethods({
  appProps, selectedShippingMethod, onShippingMethodSelect, shippingMethods, deliveryFee,
  stores, onStoreSelect, selectedStoreId, enableMaterialProgress
}) {

  useEffect(() => {
    $('select:not(.select2)').show()
    deliveryMethodAnalytics( selectedShippingMethod)
  }, [])

  let storesList =
    stores.map((store, index) => {
      return (
        {
          title: store.address ? store.presentation + ' - ' + store.address.full_presentation : store.presentation,
          value: store.id
        }
      )
    })

  return (
    <div className="methods">
      <ul className="checkout_methods">
        {
          shippingMethods && shippingMethods.map((method, index) => {
            return (
              <li className={selectedShippingMethod === method ? 'checked' : ''} key={index} onClick={() => {
                onShippingMethodSelect(method)
                deliveryMethodAnalytics(method)
              }}>
                <div>{ appProps.translations.checkout[`${method}_title`] + `${method === "delivery" && deliveryFee ? ' ( +' + deliveryFee + ' )' : ''}`}</div>
                { enableMaterialProgress === method &&
                  <div className="preloader-wrapper small active center m-l-10">
                    <div className="spinner-layer darken-4">
                      <div className="circle-clipper amber-text text-darken-4 left">
                        <div className="circle"></div>
                      </div>
                      <div className="gap-patch">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                }
                <div className="description">
                  { appProps.translations.checkout[`${method}_description`] }
                </div>
              </li>
            )
          })
        }
        { selectedShippingMethod === "pickup" && stores.length > 0 &&
          <div className="select-container store-select">
            <div className="select-wrapper">
              <div className="select-box">
                <CustomSelect
                  appProps={ appProps }
                  options={ storesList }
                  onClick={(store) => {
                    onStoreSelect(store.value)
                  }}
                  value={ selectedStoreId ? storesList.filter(s => s.value === selectedStoreId)[0].title : storesList[0].title }
                />
              </div>
            </div>
          </div>
        }
      </ul>
    </div>
  )
}
