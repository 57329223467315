import React, { useState } from "react"
import ReactDOM from 'react-dom'
import MiniCart from '../header/MiniCart'

export default function DeleteLineItem({
  appProps, lineItemId, csrfToken, location, onDelete, setLoading
}) {
  const [deleting, setDeleting] = useState(false)

  function deleteItem(lineItemId) {
    setDeleting(true)
    if (setLoading) setLoading(true)

    Rails.ajax({
      url: `/line_items/${ lineItemId }/delete`,
      type: 'POST',
      dataType: 'json',
      success: res => {
        setDeleting(false)
        if (setLoading) setLoading(false)
        if (onDelete) {
          onDelete(res.order, res.isEmpty)
        }

        let target = document.getElementById('mini-cart-ajax-container')
        if (target) ReactDOM.render(<MiniCart lineItemsCount={ res.order ? res.order.line_items.filter( item => item.origin !== 'automation' ).length : 0 } />, target)

        target = document.getElementById('top-banner-full-width-ajax-container')
        if (target && !res.order && res.isEmpty) ReactDOM.render(<React.Fragment/>, target)
      }
    })
  }

  return (
    <>
      { deleting ?
        <div className="flex-box content-center">
          <div className="spinner-wrapper">
            <div className="spinner user-auth-button">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
        :
        <div className="row actions">
          <div className="view">
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <i className="remove_icon" onClick={ () => deleteItem(lineItemId) }></i>
          </div>
        </div>
      }
    </>
  )
}
