import React from "react"
import { SliderImages } from '@reactiveonline/frontend_shared_components'
import SalesBadge from "./SalesBadge"

export default function Product({
  appProps, product, toggleBurger, isRelated
}) {
  const descriptionHtml = product.description ? product.description : " ";
  const strippedDescription = descriptionHtml.replace(/(<([^>]+)>)/gi, "");
  const darkBackgroundStyle = { background: '#f7f7f7' }
  const itemBorderStyle = { border: '1px solid #dddddd' }

  let productItemStyle = appProps.darkBackground ? darkBackgroundStyle : { backgroundColor: '#ffffff' }
  productItemStyle = appProps.itemBorder ? { ...productItemStyle, ...itemBorderStyle } : productItemStyle
  let productInStock = product.inStock && (product.totalStock > 0 || !product.totalStock)

  const productImages = product.images.length > 0 ? product.images.map( image => {
    return {
      original: image.list_view,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  return (
    <>
      <div className={ `${appProps.itemShadow ? 'tiles-box-shadow' : ''} outer-product ${ appProps.listLayout == 'menu' ? 'menu-layout' : '' }` } style={ productItemStyle }>
        <div className="inner-product">

          <div className="pointer product-wrapper-image" onClick={ ()=> window.location.href = product.path }>
            { product.onSale &&
              <SalesBadge discountRate={ product.discountRate } />
            }
            <SliderImages
              images={ productImages }
              showThumbnails={ false }
              showFullscreenButton={ false }
              showNav={ false }
              showBullets={ false }
            />
          </div>

          <div className="product-wrapper-content">
            <a className='product-title' href={ product.path } rel="nofollow noindex">
              { product.presentation }
            </a>

            { product.description &&
              <div className="short-description">
                { strippedDescription }
              </div>
            }

            <div className='product-lower-wrapper'>
              <div className='price'>{ product.displayTotalPrice }</div>
              { !product.appliedTax.includedInPrice && product.appliedTax.rate > 0 &&
                <div className='tax-amount'>
                  { `(${ appProps.translations.products.includes } ${ product.appliedTax.name } ${ Math.round(product.appliedTax.rate * 100) }%)` }
                </div>
              }
            </div>

            { !isRelated && productInStock &&
              <div className="product-button-wrapper">
                <button className="button product-button"
                        onClick={() => toggleBurger(true, product)}>
                  { appProps.translations.products.add_to_cart_button }
                </button>
              </div>
            }

            { !isRelated && !productInStock &&
              <div className='flex-box content-end'>
                <div className='availability'>
                  { appProps.translations.products.out_of_stock }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
