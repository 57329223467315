import React from 'react'
import Logo from '@reactiveonline/frontend_shared_components/components/layout/shared/Logo'

export default function Footer({ appProps }) {
  return (
    <>
      <div className='bottom-section main-container' style={{ paddingBottom: 100 }}>
        <div className='left-column'>
          <div className='copyrights' style={ { display: 'block !important' } }>
  		      <p className='centered'>
  		        { appProps.translations.general.copyright } &copy; { new Date().getFullYear() } { appProps.storeName }. { appProps.translations.general.all_rights_reserved }
  		      </p>

            { !appProps.whiteLabel &&
    		      <div className='reactive-branding'>
                { appProps.translations.general.powered_by }
                <Logo
                  appProps={ appProps }
                  target={ '_blank' }
                  logo={ appProps.preferences.admin_interface_logo }
                />
    		      </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
