import React, { useEffect } from "react"
import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"

export default function OrderTrackers({
  appProps, order, currency, renderTrackers
}) {

  useEffect(() => {
    if ( renderTrackers ) {
      if( appProps.facebookPixelId ) {
        ReactPixel.track('Purchase', {
          content_type: 'product',
          currency:     `${appProps.storeCurrency}`,
          value:        `${order.totalWithAdjustmentsValue}`,
          content_ids:  `${order.number}`,
          num_items:    `${order.line_items.count}`,
          contents:     order.line_items.map((lineItem) => {
            return {
              name:                 lineItem.variants[0].product.presentation,
              id:                   lineItem.variants[0].product.sku,
              quantity:             lineItem.quantity,
              item_price:           lineItem.total_value,
              product_vat:          lineItem.vat_value,
              total_product_vat:    lineItem.variants[0].product.taxPrice,
              total:                lineItem.variants[0].product.totalPrice
            }
          })
        })
      }

      if( appProps.googleAnalyticsId ) {
        ReactGA.ga('require', 'ecommerce');
        ReactGA.ga('ecommerce:addTransaction', {
          'id':       order.number,
          'revenue':  order.totalWithAdjustmentsValue,
          'tax':      order.vat_total_value
        });
        order.line_items.map((lineItem) => {
          return ReactGA.ga('ecommerce:addItem', {
            'id':       order.number,
            'name':     lineItem.variants[0].product.presentation,
            'sku':      lineItem.variants[0].product.sku,
            'category': lineItem.variants[0].product.presentation,
            'quantity': lineItem.quantity,
            'currency': appProps.storeCurrency,
            'price':    lineItem.variants[0].product.totalPrice,
          });
        })
        ReactGA.ga('ecommerce:send');
        ReactGA.ga('ecommerce:clear');
      }
    }
  }, [])

  return(
    <></>
  )
}
