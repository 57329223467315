import React from "react"
import CustomSelect from "../shared/CustomSelect"

import { pagingOptions } from '../../helpers/elements'
import { constructProductsListUrl } from '../../helpers/utils'

export default function Sorting({ appProps, productParams }) {
  const { page, perPage, category } = productParams

  const perPageOptions = pagingOptions()
  let selectedPagingOption = perPageOptions.filter(option => option.value === `${perPage}`)[0]

  return (
    <div className="select-container sort-products">
      <div className="select-wrapper">
        <div className="select-box">
          <CustomSelect
            options={ perPageOptions }
            translations= { appProps.translations }
            onClick={(option) => {
              window.location.href = constructProductsListUrl({
                per_page: option.value
              }, category, appProps.localeUrlPresent ? appProps.currentLocale : null)
            }}
            value={ selectedPagingOption.value }
          />
        </div>
      </div>
    </div>
  )
}
