import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'

export default function NewAddressModal({
  appProps, userId, postUrl, addressFormFields, updateData, translations,
  googleApiKey, onCheckoutSelect, userCoordinates, setModalOpen
}) {
  const initialDisabledFields = ['street_number', 'route', 'locality']
  const addressResultsStyles = {
    position: 'absolute',
    top: '100%',
    left: 0,
    background: '#ffffff',
    zIndex: 1,
    width: '100%',
    boxShadow: `rgba(0, 0, 0, 0.18) 0px 2px 4px`,
    padding: 10
  }

  const [hoverResult, setHoverResult] = useState('')
  const [placesLoading, setPlacesLoading] = useState(false)
  const [addressFields, setAddressFields] = useState({
    street_number: '',
    route: '',
    locality: '',
    sublocality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
    latitude: '',
    longitude: '',
    ...extraFields()
  })
  const [query, setQuery] = useState('')
  const [retrievingLocation, setRetrievingLocation] = useState(false)
  const [disabledFields, setDisabledFields] = useState(initialDisabledFields)
  const [placeDetails, setPlaceDetails] = useState({})
  const [map, setMap] = useState()
  const [placesService, setPlacesService] = useState()
  const [placesResults, setPlacesResults] = useState([])
  const [selectedPlace, setSelectedPlace] = useState()
  const [marker, setMarker] = useState(null)

  const instantiateMap = (location, addMarker = false) => {
    const lat = userCoordinates?.lat || 37.955390456753015
    const long = userCoordinates?.long || 23.6987858450863

    const gMap = new google.maps.Map(document.getElementById("google-map"), {
      zoom: 13,
      center: location,
      mapTypeId: 'roadmap'
    })

    const service = new google.maps.places.PlacesService(gMap)
    setMap(gMap)
    setPlacesService(service)

    if(addMarker) {
      let marker = new google.maps.Marker({
        position: location,
        map: gMap,
        draggable: true,
        animation: google.maps.Animation.DROP
      })
      google.maps.event.addListener(marker, 'dragend', () => {
        const newLat = marker.position.lat()
        const newLng = marker.position.lng()
        setAddressFromGeocoder(newLat, newLng)
      })
      setMarker(marker)
    } else {
      gMap.setZoom(11)
      setAddressFields({
        ...addressFields,
        street_number: '',
        route: '',
        locality: '',
        sublocality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        latitude: '',
        longitude: ''
      })
    }
  }

  const debouncedSave = useCallback(
    debounce((value, service) => {
      const request = {
        query: value
      }
      setPlacesLoading(true)
      service.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPlacesLoading(false)
          setPlacesResults(results)
        }
      })
    }, 1500),
    []
  )

  useEffect(() => {
    if (!document.querySelector('script#google-map-script')){
      const script = document.createElement("script")
      script.src = `//maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
      script.id = 'google-map-script'
      script.defer = true
      script.async = true
      script.addEventListener( 'load', ()=> instantiateMap(userCoordinates) )
      document.body.appendChild(script)
    } else {
      instantiateMap(userCoordinates)
    }
  }, [])

  useEffect(() => {
    if(placesResults.length == 1){
      onResultSelect(placesResults[0])
    }
  }, [placesResults])

  const handleAddressChange = event =>{
    const value = event.target.value
    setQuery(value)

    if(placesService && typeof(value) === 'string' && value.length > 2) {
      debouncedSave(value, placesService)
    } else {
      debouncedSave.cancel()
      setPlacesResults([])
      setSelectedPlace()
      if (marker) {
        marker.setMap(null)
        setMarker()
      }
    }
  }

  const onResultSelect = (place) => {
    if(marker){
      marker.setMap(null)
    }

    setMarker(new google.maps.Marker({
      position: place.geometry.location,
      map,
      title: place.name,
    }))

    map.setCenter(place.geometry.location)
    setSelectedPlace(place)
    setPlacesResults([])
  }

  useEffect(() => {
    if( selectedPlace){
      const request = {
        placeId: selectedPlace.place_id
      }
      placesService.getDetails(request, function (place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = place.address_components

          let newAddressFields = {
            ...addressFields,
            street_number: '',
            route: '',
            locality: '',
            sublocality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            latitude: '',
            longitude: ''
          }

          addressComponents.map(field => {
            const fieldType = field.types.filter(type => type in addressFields)[0]
            if(fieldType) {
              newAddressFields[fieldType] = fieldType === 'country' ? field.short_name : field.long_name
            }
          })

          if(place.geometry) {
            newAddressFields.latitude = place.geometry.location.lat()
            newAddressFields.longitude = place.geometry.location.lng()
          }

          instantiateMap({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}, true)
          setQuery(place.formatted_address)
          setAddressFields(newAddressFields)
          setDisabledFields( initialDisabledFields.filter( disabledField => disabledField !== 'street_number' || newAddressFields.street_number !== '') )
          setPlaceDetails(place)
        }
      })
    }
  }, [selectedPlace])

  function setAddressFromGeocoder(newLatitude, newLongitude, initializeMap = false) {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: {lat: newLatitude, lng: newLongitude} }, (results, status) => {
      if(results.length > 0) {
        let addressObject = results[0]
        const addressComponents = addressObject.address_components

        let newAddressFields = {
          ...addressFields,
          street_number: '',
          route: '',
          locality: '',
          sublocality: '',
          administrative_area_level_1: '',
          country: '',
          postal_code: '',
          latitude: '',
          longitude: ''
        }

        addressComponents.map(field => {
          const fieldType = field.types.filter(type => type in addressFields)[0]
          if(fieldType) {
            newAddressFields[fieldType] = fieldType === 'country' ? field.short_name : field.long_name
          }
        })

        if(addressObject.geometry) {
          newAddressFields.latitude = addressObject.geometry.location.lat()
          newAddressFields.longitude = addressObject.geometry.location.lng()
        }

        if(initializeMap) {
          instantiateMap({lat: newLatitude, lng: newLongitude}, true)
        }
        setQuery(results[0].formatted_address)
        setAddressFields(newAddressFields)
        setRetrievingLocation(false)
        setDisabledFields(initialDisabledFields.filter( disabledField => disabledField !== 'street_number' || newAddressFields.street_number !== ''))
      }
    })
  }

  function extraFields() {
    let fields = {}
    addressFormFields.map(field => {
      fields[`${field.name}`] = ''
    })

    return fields
  }

  function closeAddressModal() {
    setModalOpen(false)
  }

  function getCurrentLocation() {
    setPlacesLoading(true)
    if(navigator.geolocation) {
      setRetrievingLocation(true)
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        setAddressFromGeocoder(latitude, longitude, true)
        setPlacesLoading(false)
      })
    } else {
      setPlacesLoading(false)
      appProps.flashMessage.show('Unable to retrieve current location.', 'error')
    }
  }

  function submitNewAddress() {
    let formData = new FormData()
    if(userId) {
      formData.append('user_id', userId)
    }

    for (let key in addressFields) {
      formData.append(`associable_address[address_attributes][${key}]`, addressFields[key])
    }

    Rails.ajax({
      url: postUrl,
      type: 'POST',
      dataType: 'json',
      data: formData,
      success: res => {
        appProps.flashMessage.show(res.response, 'success')
        if(updateData) {
          updateData({addresses: res.addresses})
        }
        if(onCheckoutSelect) {
          onCheckoutSelect(res.newAddress)
        }
        if(res.redirectUrl) {
          window.location.href = res.redirectUrl
        }
        closeAddressModal()
      },
      error: res => {
        if(res.response.length > 4) {
          appProps.flashMessage.show(translations.flash_messages.fill_all_required_fields, 'error')
        } else {
          appProps.flashMessage.show(res.response, 'success')
        }
      }
    })
  }

  function renderFields() {
    return addressFormFields.map((field, index) => {
      let input
      let isDisabled = disabledFields.includes(field.name)

      if(field.type === 'text') {
        input = <input
          type={field.type}
          name={`associable_address[address_attributes][${field.name}]`}
          id={`address_${field.name}`}
          className={`input-field ${ isDisabled ? '' : 'required' }`}
          style={ !isDisabled && field.name === 'street_number' && addressFields[field.name] === '' ? { border: '1px solid', borderColor: 'red' } : {} }
          placeholder={field.placeholder}
          value={addressFields[field.name]}
          disabled={ isDisabled }
          onChange={(event) => {
            setAddressFields({...addressFields, [field.name]: event.target.value})
          }}
          onFocus={() => {}}
        />
      }

      return (
        <div key={index} className={`field address_${field.name}`} style={{ padding: '0px 10px', width: '50%' }}>
          <label htmlFor={field.name} >
            {field.title}
            {field.required && <abbr className="required" title={ translations.general.required_field }>*</abbr>}
          </label>
          {input}
        </div>
      )
    })
  }

  let content =
    <div className="new_maps_address">
      <div style={{ fontWeight: '700', fontSize: 20 }}>{ translations.user.add_address }</div>
      <div className='field' style={{ position: 'relative' }}>
        <input
          type='text'
          value={ query }
          placeholder={ translations.addresses.search_placeholder }
          onChange={ handleAddressChange }
        />
        { placesLoading && <div style={{ position: 'absolute', right: 40, top: '40%' }}><Loader size='small' position='absolute' /></div> }
        { !placesLoading &&
          <div style={{ position: 'absolute', right: 20, top: '40%', fontSize: 20 }} className="pointer fa-light fa-location-crosshairs" onClick={ getCurrentLocation }></div>
        }
        { placesResults.length > 1 &&
          <div style={ addressResultsStyles }>
            { placesResults.map( (place, index) => (
              <div
                key={ index }
                style={{ cursor: 'pointer', marginBottom: 5, ...( hoverResult && hoverResult == place && {color: appProps.colorScheme}) }}
                onMouseEnter={ ()=> setHoverResult(place) }
                onMouseLeave={ ()=> setHoverResult('') }
                onClick={ () => onResultSelect(place) }
              >
                { `${place.name} - ${place.formatted_address}` }
              </div>
            ))}
          </div>
        }
      </div>
      <div className="flex-box" style={{ marginTop: 40 }}>
        <div className="maps-wrapper" style={{ minWidth: '50%' }}>
          <div id="google-map" style={{ width: '100%', height: 300 }}></div>
        </div>
        <div className="address flex-box flex-wrap" style={{ minWidth: '50%' }}>
          { renderFields() }
        </div>
      </div>
    </div>

  return (
    <>
      <Modal
        visible
        mode='large'
        closeModal={ ()=> closeAddressModal() }
        acceptAction={ ()=> submitNewAddress() }
        saveText={ translations.user.save_address }
      >
        { content }
      </Modal>
    </>
  )
}
