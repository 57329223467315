import React, { useState, useEffect, useRef } from "react"
import ReactDOM from 'react-dom'
import { Loader } from '@reactiveonline/frontend_shared_components'

import SideOptions from "./SideOptions"
import Button from "../Button"
import TopBannerFullWidth from "../shared/TopBannerFullWidth"
import MiniCart from '../header/MiniCart'
import HeaderIcons from '../header/HeaderIcons'
import BurgerCart from '../cart/BurgerCart'
import Quantity from '../shared/Quantity'
import { addToCartAnalytics } from '../../helpers/analytics'

export default function AddToCart({
  appProps, product, lineItemQuantity, csrfToken, lineItemId, postUrl,
  lineItemSelectedOptions, productNotes, toggleBurger, isAdmin
}) {
  const [bundleProducts, setBundleProducts] = useState([])
  const [productsSelectableData, setProductsSelectableData] = useState(product.class_name === "bundle" ? [] : [{id: product.id}])
  const [totalValue, setTotalValue] = useState(product.totalPrice)
  const [quantity, setQuantity] = useState(lineItemQuantity || 1)
  const [loading, setLoading] = useState(false)

  const formRef = React.useRef()

  useEffect( () => {
    if(product.class_name === "bundle") {
      getBundleProducts()
    }
  }, [])

  useEffect( () => {
    setTotalValue(product.totalPrice)
    setQuantity(1)
    setProductsSelectableData(product.class_name === "bundle" ? [] : [{id: product.id}])
  }, [product])

  function getBundleProducts() {
    setLoading(true)
    Rails.ajax({
      url: `/products/${product.id}/get_bundle_products`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setBundleProducts(res.products)
        setProductsSelectableData(res.products.map( p => { return { id: p.id } }))
        setLoading(false)
      }
    })
  }

  function updateProductTotal(productId, total, selectedOptions) {
    let tmpState = productsSelectableData

    let data = tmpState.filter(p => p.id == productId)[0]
    data.selectedOptions = selectedOptions
    data.totalValue = total
    setProductsSelectableData(tmpState)
    setTotalValue( ( product.totalPrice + tmpState.map(p => p.totalValue).reduce((a, b) => a + b, 0)).toFixed(2) )
  }

  function onChangeQuantity(quantity) {
    setQuantity(quantity)
  }

  function renderProductCartContainer(item, index) {
    const data = productsSelectableData.filter(p => p.id == item.id)[0]
    if (!data) {
      return null
    }
    let alreadySelectedOptions = lineItemSelectedOptions && lineItemSelectedOptions.filter(s => s.product_id === item.id)[0]

    return (
      <div key={ index } className={`product-row  ${product.class_name === 'bundle' ? 'card bundle' : ''}`}>
        <input type="hidden" name={`line_item[variants][${index}][variant_options]`} value={ JSON.stringify(data.selectedOptions) || '[]' } />
        <input type="hidden" name={`line_item[variants][${index}][product_id]`} value={item.id} />
        { product.class_name === "bundle" &&
          <div className="meal-preview-wrapper">
            { item.images.length > 0 &&
              <img src={ item.images[0].thumbnail } />
            }
            <h4>{item.presentation}</h4>
          </div>
        }
        <SideOptions
          key={index}
          product={ item }
          modelName={ product.class_name }
          lineItemSelectedOptions={ alreadySelectedOptions ? alreadySelectedOptions.selected_options : null }
          onChangedTotal={ (total, selectedOptions) => {
            updateProductTotal(item.id, total, selectedOptions)
          }}
        />
      </div>
    )
  }

  function addToCart(event) {
    setLoading(true)
    addToCartAnalytics(product, appProps)
    if (!isAdmin) {
      event.preventDefault()
      const form = formRef.current

      Rails.ajax({
        url: postUrl,
        type: 'POST',
        data: new FormData(form),
        dataType: 'json',
        success: res => {
          if(res.error_detected){
            appProps.flashMessage.show(res.message.failure, 'error')
          }
          else {
            appProps.flashMessage.show(res.message.success, 'success')
          }

          if (toggleBurger) {
            toggleBurger(false)
          }
          setLoading(false)

          let target = document.getElementById('mini-cart-ajax-container')
          if (target && !res.error_detected) ReactDOM.render(<MiniCart appProps={ appProps } order={ res.order } />, target)

          target = document.getElementById('burger-cart-order-value')

          if (target) {
            const orderEvent = new Event('order-change')
            target.value = JSON.stringify(res.order)
            target.dispatchEvent(orderEvent)
          }

          target = document.getElementById('top-banner-full-width-ajax-container')

          if(target) {
            if(res.message.success) {
              ReactDOM.render(
                <TopBannerFullWidth
                  appProps={ appProps }
                  infoMessage={`${appProps.translations.cart.added_to_cart}`}
                  linkTitle={ `${appProps.translations.checkout.checkout_button}` }
                  linkTo={'/checkout'}
                  order={ res.order } />,
                target
              )
            } else {
              ReactDOM.render(
                <TopBannerFullWidth
                  appProps={ appProps }
                  infoMessage={`${res.message.failure}`}
                  linkTitle={ null }
                  linkTo={ null }
                  order={ res.order } />,
                target
              )
            }
          }

          target = document.getElementById('header-icons-ajax-container')
          if (target && !res.error_detected) ReactDOM.render(<HeaderIcons appProps={ appProps } order={ res.order } />, target)

          scrollTo({
            top: 0,
            behavior: 'smooth',
            block: 'start'
          })
        }
      })
    }
  }

  let chooseIngredients
  if(product.class_name === "bundle") {
    chooseIngredients = bundleProducts.map((product, index) => renderProductCartContainer(product, index))
  } else {
    chooseIngredients = renderProductCartContainer(product, 0)
  }

  let totalPrice = totalValue
  if(product.currency.symbol_first) {
    totalPrice = `${product.currency.symbol}${new Intl.NumberFormat().format(totalPrice)}`
  } else {
    totalPrice = `${new Intl.NumberFormat().format(totalPrice)} ${product.currency.symbol}`
  }

  let currentQuantity = quantity

  return (
    <div className="product-options-wrapper">
      <div className="product-options">
        <form id='add_to_cart_form' ref={ formRef }>
          <input type="hidden" name="authenticity_token" value={ csrfToken } />
          { lineItemId && <input type="hidden" name="_method" value="patch" /> }
          { product.class_name === "bundle" && <input type="hidden" name="line_item[bundle_id]" value={product.id} /> }

          { chooseIngredients }

          <div className="product-notes">
            <label>{ appProps.translations.products.product_notes_label}</label>
            <textarea id="product-notes" name="notes" placeholder={ appProps.translations.products.product_notes_placeholder }>
              { productNotes && productNotes[0].notes }
            </textarea>
          </div>

          <div>
            <div className="button-container">
              <div className="total-value button plain">
                { totalPrice }
              </div>
              <Quantity
                quantity={ quantity }
                onChange={ onChangeQuantity }
                flashMessage={ appProps.flashMessage }
              />
              { loading ?
                <div className='button inverted' style={{ minWidth: 120 }}>
                  <Loader
                    position='center'
                    size='medium'
                  />
                </div>
                :
                <Button
                  value={ appProps.translations.products.add_to_cart_button }
                  buttonType={'button'}
                  className="button"
                  onClick={ event => addToCart(event) }
                />
              }
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
