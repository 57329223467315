import React, { useEffect } from "react"

import LineItem from "./LineItem"
import CartTotals from "./CartTotals"

import { cartViewAnalytics, purchaseAnalytics, facebookPixelId, googleAnalyticsId } from '../../helpers/analytics'

export default function CartItems({
  appProps, order, csrfToken, disableRemove, hideTitle, onDelete, onChangeQuantity,
  disableQuantityChanging, setLoading, enableTracker
}) {

  useEffect(() => {
    !enableTracker && cartViewAnalytics(order)
    if(enableTracker) {
      purchaseAnalytics(order)
    }
  }, [])

  return (
    <div>
      { !hideTitle &&
        <div className="section-heading">
          <div className="heading-text">
            { appProps.translations.cart.items }
          </div>
        </div>
      }
      <div className="order-contents">
        { order && order.line_items.map((lineItem, index) =>
          <LineItem
            appProps={ appProps }
            lineItem={ lineItem }
            csrfToken={ csrfToken }
            key={ index }
            disableRemove={ disableRemove }
            location="cart"
            onDelete={ onDelete }
            onChangeQuantity={ onChangeQuantity }
            disableQuantityChanging={ disableQuantityChanging }
            setLoading={ setLoading }
          />
        )}
      </div>
    </div>
  )
}
