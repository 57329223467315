import React, { useState, useEffect} from "react"
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'
import SwiperCore, {
  Navigation, Pagination, Scrollbar, A11y, EffectFade, EffectCube, EffectCoverflow,
  EffectFlip, Thumbs, Lazy, Zoom, Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([
  Navigation, Pagination, Scrollbar, A11y, EffectFade, EffectCube,
  EffectCoverflow, EffectFlip, Thumbs, Lazy, Zoom, Autoplay
])

export default function RecentOrders({ appProps, setCurrentOrder, fetchUpdatedOrder }) {
  const [recentOrders, setRecentOrders] = useState([])
  const [orderModal, setOrderModal] = useState(false)
  const [lineItems, setLineItems]       = useState([])
  const [loading, setLoading]           = useState(false)

  useEffect(() => {
    fetchRecentOrders()
  }, []);

  function fetchRecentOrders() {
    Rails.ajax({
      type: "GET",
      url: `${appProps.fetchRecentOrdersPath}`,
      dataType: "json",
      success: res => {
        setRecentOrders(res.orders)
      }
    })
  }

  function populateRecentOrder() {
    setOrderModal(false)
    setLoading(true)

    let fd = new FormData()
    fd.append('selected_order_id', orderModal.id)

    Rails.ajax({
      type: "POST",
      url: `${appProps.populatePreviousOrderPath}`,
      dataType: "json",
      data: fd,
      success: res => {
        if (res.order) {
          setLoading(false)
          appProps.flashMessage.show(res.message, 'success')
          setCurrentOrder(res.order)
          fetchUpdatedOrder(res.order.id)
        }
      },
      error: res => {
        setLoading(false)
        appProps.flashMessage.show(res.error, 'error')
      }
    })
  }

  return (
    <>
      { recentOrders && recentOrders.length > 0 &&
        <div className='recent-orders-wrapper'>
          <div className='title'>
            <b>{ appProps.translations.cart.order_again }</b>
          </div>
          <Swiper
            effect={ 'flip' }
            spaceBetween={ 10 }
            slidesPerView={ 2 }
            navigation={ true }
            pagination={ false }
            scrollbar={ false }
          >
            { recentOrders.map( (order, index)=> {
              return (
                <SwiperSlide key={ order.id } style={{ position: 'relative', height: '100%', backgroundColor: 'transparent', alignSelf: 'center' }}>
                  <div key={ order.id } className='order-wrapper flex-box items-center content-space-between'>
                    <div className='flex-1 flex-box flex-column'>
                      <div className='order-info'>{ order.completed_at_formatted }</div>
                      <div className='order-info'>{ order.totalWithAdjustments }</div>
                      <div className='flex-box flex-wrap'>
                        { order.line_items && order.line_items.length > 0 && order.line_items.map( (lineItem, index)=> {
                          return(
                            <div key={ index } className='flex-box' style={{ marginRight: 3 }}>
                              ({ lineItem.quantity }){ lineItem.productTitle }
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div style={{ position: 'relative', height: '100%' }}>
                      <i className='flex-box items-center content-center pointer fa-light fa-cart-plus' onClick={ ()=> setOrderModal(order) } />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      }

      { orderModal &&
        <div style={{ zIndex: 2, position: 'fixed' }}>
          <Modal
            visible
            mode='small'
            icon={ 'cart' }
            acceptAction={ ()=> populateRecentOrder() }
            saveText={ appProps.translations.products.add_to_cart_button }
            closeModal={ ()=> setOrderModal(false) }
            abortAction={ ()=> setOrderModal(false) }
            abortText={ appProps.translations.general.cancel }
          >
            <div className='flex-box flex-column items-center'>
              <div className='centered' style={{ marginBottom: 10 }}>{ orderModal.number }</div>
              <div className='flex-box flex-column' style={{ fontSize: 13, marginBottom: 20 }}>
                { orderModal.line_items && orderModal.line_items.length > 0 && orderModal.line_items.map( (lineItem, index)=> {
                  return(
                    <div key={ index } className='flex-box' style={{ marginRight: 3 }}>
                      { lineItem.productTitle } x ({ lineItem.quantity }) { lineItem.total }
                    </div>
                  )
                })}
              </div>
              <div>{ orderModal.total }</div>
              <div className='centered' style={{ marginTop: 10, fontSize: 13 }}>* { appProps.translations.cart.order_again_modal_description }</div>
            </div>
          </Modal>
        </div>
      }

      { loading &&
        <Loader
          fullPage
          size='large'
        />
      }

    </>
  )
}
