import React, { useState, useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { BankDetails, StripeCheckoutForm } from "@reactiveonline/frontend_shared_components"

import { paymentMethodAnalytics } from '../../helpers/analytics'

export default function PaymentMethods({
  appProps, paymentMethods, checkoutPaymentMethodSelectedId, submitStripeCard, onCheckoutSelect,
  stripePublishableKey, setStripeError, completeOrder, stripePaymentIntentClientSecret, stripePaymentIntentActionType,
  stripeSetupIntentClientSecret, stripeSetupIntentActionType, orderTotal, orderId, cardsPath, setCurrentCardPath,
  setLoading, stripeBillingDetails, type
}) {
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    if(stripePublishableKey) {
      setStripePromise(loadStripe(stripePublishableKey))
    }
  }, [])

  useEffect(() => {
    paymentMethodAnalytics(paymentMethods.length > 0 ? paymentMethods[0].presentation : '')
  }, [])

  return (
    <div className="methods">
      <ul className="checkout_methods">
        {
          paymentMethods.map((method, index) => {
            if (orderTotal > 0 || (orderTotal === 0 && !method.type.includes('ReactiveCore::Gateway::'))) {
              return (
                <li className={checkoutPaymentMethodSelectedId === method.id ? 'checked' : ''} key={index} onClick={() => {
                  onCheckoutSelect(method)
                  paymentMethodAnalytics(method.presentation)
                }}>
                  <div>{ method.presentation }</div>
                  <div dangerouslySetInnerHTML={{__html: method.description}} className="description"></div>
                  { method.type === 'BankTransfer' && checkoutPaymentMethodSelectedId === method.id && !!method.bankDetails.length &&
                    <BankDetails
                      appProps={ appProps }
                      bankDetails={ method.bankDetails }
                      hideExtraInfo
                    />
                  }
                  { method.type === "StripePayment" && checkoutPaymentMethodSelectedId === method.id &&
                    <Elements stripe={ stripePromise }>
                      <StripeCheckoutForm
                        appProps={ appProps }
                        submitStripeCard={submitStripeCard}
                        setStripeError={setStripeError}
                        completeOrder={completeOrder}
                        stripePaymentIntentClientSecret={stripePaymentIntentClientSecret}
                        stripePaymentIntentActionType={stripePaymentIntentActionType}
                        stripeSetupIntentClientSecret={stripeSetupIntentClientSecret}
                        stripeSetupIntentActionType={stripeSetupIntentActionType}
                        orderId={ orderId }
                        type={ type }
                        paymentMethodId={ method.id }
                        cardsPath = { cardsPath }
                        setIsLoading = { setLoading }
                        setCurrentCardPath = { setCurrentCardPath }
                        billingDetails={ stripeBillingDetails }
                      />
                    </Elements>
                  }
                </li>
              )
            }
          })
        }
      </ul>
    </div>
  )
}
