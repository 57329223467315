import React, { useState, useEffect, useRef } from "react"
import ReactDOM from 'react-dom'
import { Loader } from '@reactiveonline/frontend_shared_components'
import SideOptions from "../products/SideOptions"
import Button from "../Button"
import Quantity from '../shared/Quantity'

export default function AddToCart({
  appProps, product, lineItemQuantity, lineItemId, lineItemSelectedOptions,
  productNotes, lineItems, setLineItems, closeModal
}) {
  const [productsSelectableData, setProductsSelectableData] = useState(product.class_name === "bundle" ? [] : [{id: product.id}])
  const [bundleProducts, setBundleProducts] = useState([])
  const [totalValue, setTotalValue] = useState(product.totalPrice)
  const [quantity, setQuantity] = useState(lineItemQuantity || 1)
  const [notes, setNotes] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect( () => {
    if(product.class_name === "bundle") {
      getBundleProducts()
    }
  }, [])

  useEffect( () => {
    setTotalValue(product.totalPrice)
    setQuantity(1)
    setProductsSelectableData(product.class_name === "bundle" ? [] : [{id: product.id}])
  }, [product])

  function getBundleProducts() {
    setLoading(true)
    Rails.ajax({
      url: `/products/${product.id}/get_bundle_products`,
      type: 'GET',
      dataType: 'json',
      success: res => {
        setBundleProducts(res.products)
        setProductsSelectableData(res.products.map( p => { return { id: p.id } }))
        setLoading(false)
      }
    })
  }

  function updateProductTotal(productId, total, selectedOptions) {
    let tmpState = productsSelectableData

    let data = tmpState.filter(p => p.id == productId)[0]
    data.selectedOptions = selectedOptions
    data.totalValue = total
    setProductsSelectableData(tmpState)
    setTotalValue( ( product.priceLists.dine_in.price + tmpState.map(p => p.totalValue).reduce((a, b) => a + b, 0)).toFixed(2) )
  }

  function onChangeQuantity(quantity) {
    setQuantity(quantity)
  }

  function renderProductCartContainer(item, index) {
    const data = productsSelectableData.filter(p => p.id == item.id)[0]
    if (!data) {
      return null
    }
    let alreadySelectedOptions = lineItemSelectedOptions && lineItemSelectedOptions.filter(s => s.product_id === item.id)[0]

    return (
      <div key={ index }>
        { product.class_name === "bundle" &&
          <div className="meal-preview-wrapper">
            { item.images.length > 0 &&
              <img src={ item.images[0].thumbnail } />
            }
            <h4>{item.presentation}</h4>
          </div>
        }
        <SideOptions
          key={index}
          product={{ ...item, totalPrice: item.priceLists.dine_in.price }}
          modelName={ product.class_name }
          lineItemSelectedOptions={ alreadySelectedOptions ? alreadySelectedOptions.selected_options : null }
          onChangedTotal={ (total, selectedOptions) => {
            updateProductTotal(item.id, total, selectedOptions)
          }}
          setLoading={ setLoading }
        />
      </div>
    )
  }

  let chooseIngredients
  if(product.class_name === "bundle") {
    chooseIngredients = bundleProducts.map((product, index) => renderProductCartContainer(product, index))
  } else {
    chooseIngredients = renderProductCartContainer(product, 0)
  }

  let totalPrice = totalValue
  if(product.currency.symbol_first) {
    totalPrice = `${product.currency.symbol}${new Intl.NumberFormat().format(totalPrice)}`
  } else {
    totalPrice = `${new Intl.NumberFormat().format(totalPrice)} ${product.currency.symbol}`
  }

  let currentQuantity = quantity

  function addToCart() {
    setLoading(true)

    let _lineItems = [...lineItems]
    const lineItem = {
      ...(product.class_name === "bundle" && { bundle_id: product.id }),
      uid: `nli-${ new Date().getTime() }`,
      quantity: quantity,
      specialInstructions: notes,
      unitTotalValue: totalValue,
      totalValue: totalValue * quantity,
      productTitle: product.presentation,
      variants: productsSelectableData.map(variant => ({
        productId: variant.id,
        selectedOptions: variant.selectedOptions
      })),
      statuses: []
    }
    for (var i = 0; i < lineItem.quantity; i++) {
      lineItem.statuses.push({
        uid: `nst-${ new Date().getTime() }`,
        status: 'processing',
        lineItemUid: lineItem.uid,
        paymentMethod: null
      })
    }

    setLineItems(prevLineItems => ([...prevLineItems, lineItem]))
    closeModal()
    setLoading(false)
  }

  return (
    <>
      <div style={{ fontWeight: 700, fontSize: 20, marginBottom: 30 }}>{ product.presentation }</div>
      <div className="product-options-wrapper">
        <div className="product-options">
          <div>
            { chooseIngredients }
            <div className="product-notes">
              <label>{ appProps.translations.products.product_notes_label }</label>
              <textarea
                id="product-notes"
                placeholder={ appProps.translations.products.product_notes_placeholder }
                value={ notes }
                onChange={ event => setNotes(event.target.value) }
              />
            </div>

            <div>
              <div className="button-container">
                <div className="total-value button plain">
                  { `${ product.currency.symbol }${ quantity * totalValue }` }
                </div>
                <Quantity
                  quantity={ quantity }
                  onChange={ onChangeQuantity }
                  flashMessage={ appProps.flashMessage }
                />
                <Button
                  value={ lineItemSelectedOptions ? appProps.translations.products.update_product_button : appProps.translations.products.add_to_cart_button }
                  className="button"
                  onClick={ () => addToCart() }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      { loading &&
        <Loader
          fullPage
          size='large'
        />
      }
    </>
  )
}
