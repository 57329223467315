import React from "react"
import Modal from "react-modal"
import ReactDOM from "react-dom"

import AddToCart from "../products/AddToCart"

const customStyles = {
  content : {
    width                 : '80%',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    padding               : '20px',
    transform             : 'translate(-50%, -50%)',
    background            : '#f8f8f8',
    borderRadius          : '2px'
  }
}

export default class EditSelectedOptionsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false
    }

    Modal.setAppElement('#edit-selected-options-modal-place-' + this.props.lineItemId)

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({modalIsOpen: true})
  }

  afterOpenModal() {

  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    })
  }

  render() {
    const { product, translations, csrfToken, lineItemId, postUrl, lineItemSelectedOptions, lineItemQuantity, productNotes } = this.props

    return (
      <React.Fragment>
        <button type="button" onClick={this.openModal} id={`open-edit-selected-options-modal-${this.props.lineItemId}`} style={{display: 'none'}}></button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          translations={translations}>

          <div className="flex-box content-end">
            <i className="fa-light fa-close left" onClick={this.closeModal}/>
          </div>

          <AddToCart
            product={ product }
            postUrl={ postUrl }
            lineItemId={ lineItemId }
            translations={ translations }
            csrfToken={ csrfToken }
            lineItemSelectedOptions={ lineItemSelectedOptions }
            lineItemQuantity={ lineItemQuantity }
            productNotes={ productNotes }
            isAdmin
          />
        </Modal>
      </React.Fragment>
    )
  }
}
