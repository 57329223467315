import React, { lazy, useState, useEffect } from 'react'
import cookie from 'react-cookies'
import { App, AuthorizableModal } from '@reactiveonline/frontend_shared_components'
import HeaderIcons from './header/HeaderIcons'
import BelowHeaderPlacement from './categories/TopCategories'
import { additionalElements } from './builder/additionalElements'

// pass 'packageComponent' if the component should be imported inside the package
// pass 'includePageBuilder' if you want to include the additionalElements of the page builder

export default function AppLayout(props) {
  const [orderingMethod, setOrderingMethod] = useState(cookie.load("orderingMethod") )

  let PageContent = null
  if (!props.packageComponent) {
    PageContent = lazy( () => import(`./${ props.componentPath }`) )
  }

  useEffect( ()=> {
    if (!orderingMethod) {
      setOrderingMethod(cookie.load("orderingMethod"))
    }
  }, [])

  return (
    <App
      { ...props }
      packageComponent={ props.packageComponent && !(props.appProps.userFormLoginType && props.appProps.notAuthorized) }
      PageContent={ props.appProps.userFormLoginType && props.appProps.notAuthorized ? AuthorizableModal : PageContent }
      productsPath={ props.productsPath }
      orderingMethod={ orderingMethod }
      appProps={{
        ...props.appProps,
        BelowHeaderPlacement: BelowHeaderPlacement,
        HeaderIcons: HeaderIcons,
        pageBuilderAdditionalElements: props.includePageBuilder ? additionalElements({ appProps: props.appProps }) : {}
      }}
    />
  )
}
