import React, { useState, useEffect } from 'react'

export default function Billing({ appProps, pageName, pageTitle, lastOrder }) {
  // TODO: adjust this when we have the account's cards functionality
  // const paymentMethods = appProps.paymentMethods.filter(p => p.length > 0 )
  //
  // let initialCards = {}
  // paymentMethods.forEach((p, i) => {
  //   initialCards[p.id] = p.cards
  // });
  //
  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods.length > 0 ? paymentMethods[0].id : 0)
  // const [cards, setCards] = useState(initialCards)
  //
  // const setPrimaryCard = (card, paymentMethodId) => {
  //   const { setCurrentCardPath } = props
  //
  //   if (!card.current) {
  //     let fd = new FormData()
  //     fd.append('card_details[id]', card.id)
  //     fd.append('card_details[payment_method_id]', paymentMethodId)
  //
  //     Rails.ajax({
  //       type: 'POST',
  //       url: setCurrentCardPath,
  //       dataType: 'json',
  //       data: fd,
  //       success: res => {
  //         if (res.cards) {
  //           let newCards = { ...cards }
  //           newCards[paymentMethodId] = res.cards
  //           setCards(newCards)
  //         }
  //       },
  //       error: res => {
  //         appProps.flashMessage.show(res.error, 'error')
  //       }
  //     })
  //   }
  // }
  //
  // const togglePaymentMethod = (paymentMethodId) => {
  //   selectedPaymentMethod == setSelectedPaymentMethod(paymentMethodId)
  // }
  //
  // const destroyCard = (card, paymentMethodId) => {
  //   const { destroyCardPath } = props
  //
  //   let fd = new FormData()
  //   fd.append('card_details[id]', card.id)
  //
  //   Rails.ajax({
  //     type: 'POST',
  //     url: destroyCardPath,
  //     dataType: 'json',
  //     data: fd,
  //     success: res => {
  //       let newCards = { ...cards }
  //       newCards[paymentMethodId] = newCards[paymentMethodId].filter(c => c.id != card.id)
  //       setCards(newCards)
  //     },
  //     error: res => {
  //       appProps.flashMessage.show(res.error, 'error')
  //     }
  //   })
  // }
  //
  // let paymentProviders =
  //   <div className='card'>
  //     <div className='no_billing payment_icon'>
  //       <h2>No billing added</h2>
  //     </div>
  //   </div>
  //
  // if (paymentMethods.length) {
  //   paymentProviders =
  //     <div className='card'>
  //       <div className='payment-providers-wrapper'>
  //         <h5>{ appProps.translations.account.billing_subtitle }</h5>
  //         <div className='vertical-tabs'>
  //           <div className='tab-head'>
  //             { paymentMethods.map((paymentMethod, index) => (
  //               <div className={`vertical-tab-item ${selectedPaymentMethod == paymentMethod.id ? 'selected' : ''}`} key={ index } onClick={ () => togglePaymentMethod(paymentMethod.id) }>
  //                 σκατα
  //               </div>
  //             ))}
  //           </div>
  //           <div className='tab-body'>
  //             <div id="payment-methods-list">
  //               { paymentMethods.map(paymentMethod => {
  //                   if (selectedPaymentMethod == paymentMethod.id && cards[paymentMethod.id]) {
  //                     return (
  //                       <React.Fragment key={ paymentMethod.id }>
  //                         <div className="payment-method-title option">
  //                           <div className={`name ${paymentMethod.type}`}>
  //                             { paymentMethod.title }
  //                           </div>
  //                         </div>
  //                         <div className='tokenized-cards-wrapper'>
  //                           { cards[paymentMethod.id].map(card => (
  //                             <div
  //                               key={ card.id }
  //                               className={ `tokenized-card billing ${ card.current ? 'current' : '' }` }
  //                             >
  //                               <span
  //                                 className="tokenized-card-details"
  //                                 onClick={ () => setPrimaryCard(card, paymentMethod.id) }>
  //                                   <img src={ `https://storage.googleapis.com/reactive_files_eu/payment_processors/card_issuers/${ card.issuer }.svg` } style={{ width: 50 }}/>
  //                                   { ['amex', 'diners', 'discover', 'maestro', 'mastercard', 'visa'].includes(card.issuer) &&
  //                                     <div className='tokenized-card-pan'>
  //                                       { `********${ card.panLast }` }
  //                                     </div>
  //                                   }
  //                               </span>
  //                               { !card.current && <span className="delete-card-icon" onClick={ () => destroyCard(card, paymentMethod.id) }/> }
  //                             </div>
  //                             ))
  //                           }
  //                         </div>
  //                       </React.Fragment>
  //                     )
  //                   }
  //                 })
  //               }
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  // }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>
      { lastOrder &&
        <div className='card'>
          <div className='billing-address flex-column'>
            <div>
              <h5>
                Your current delivery address used in your recent transaction.
              </h5>
            </div>
            <div className='address flex-box flex-wrap' style={{ marginTop: 20 }}>
              <div className='address-row flex-box flex-column flex-1'>
                <div className='address-field-name'><b>Full Address</b></div>
                <p>{ lastOrder.delivery_address.full_presentation }</p>
              </div>
              { lastOrder.delivery_address.mobile_phone &&
                <div className='address-row flex-box flex-column flex-1'>
                  <div className='address-field-name'><b>Mobile phone</b></div>
                  <p>{ lastOrder.delivery_address.mobile_phone }</p>
                </div>
              }
              { lastOrder.delivery_address.phone &&
                <div className='address-row flex-box flex-column flex-1'>
                  <div className='address-field-name'><b>Phone</b></div>
                  <p>{ lastOrder.delivery_address.phone }</p>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
