import React, { useState, useEffect } from 'react'
import Address from "../shared/Address"
import NewAddressModal from "../../shared/NewAddressModal"

export default function Addresses({
  appProps, pageName, pageTitle, addressFormFields, newAddressPath, googleApiKey,
  userCoordinates, disableActions, checkoutAddressSelectedId, onCheckoutSelect
}) {
  const [addresses, setAddresses] = useState(appProps.user.addresses)
  const [addressModal, setAddressModal] = useState(false)

  function updateData(data){
    setAddresses(data.addresses)
  }

  return (
    <>
      <div className='section-title flex-box content-space-between items-center'>
        <h3>{ pageTitle }</h3>
        <div className="button-wrapper flex-box content-center items-center">
          <div className="button" onClick={ () => setAddressModal(true) }>
            { appProps.translations.addresses.add }
          </div>
        </div>
      </div>
      <div className='card addresses'>
        { addresses.length > 0 ?
          <div className="address_wrapper">
            { addresses.map((address, index) => {
              return (
                <Address
                  key={ index }
                  address={ address }
                  appProps={ appProps }
                  userId={ appProps.user.id }
                  disableActions={ disableActions }
                  updateData={ updateData }
                  checkoutAddressSelectedId={ checkoutAddressSelectedId }
                  onCheckoutSelect={ onCheckoutSelect }
                />
              )
            })}
          </div>
        :
          <div className="tab_content addresses">
            <div className="no_data addresses_icon">
              <h2>
                { appProps.translations.addresses.no_addresses_message }
              </h2>
            </div>
          </div>
        }
      </div>

      { addressModal &&
        <NewAddressModal
          appProps={ appProps }
          userId={ appProps.user.id }
          postUrl={ newAddressPath }
          addressFormFields={ addressFormFields }
          updateData={ updateData }
          translations={ appProps.translations }
          googleApiKey={ googleApiKey }
          onCheckoutSelect={ onCheckoutSelect }
          userCoordinates={ userCoordinates }
          setModalOpen={ setAddressModal }
        />
      }
    </>
  )
}
