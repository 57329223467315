import React, { useState, useEffect } from "react"
import { Content } from '@reactiveonline/frontend_shared_components/builder'

import ListStyles from "./ListStyles"
import Sorting from "./Sorting"
import SideCategories from "../categories/SideCategories"
import ProductBurger from "./ProductBurger"
import ProductItem from "./ProductItem"
import ChooseMethodModal from "./ChooseMethodModal"
import Pagination from './Pagination'

import { loadProductListAnalytics } from '../../helpers/analytics'

export default function ProductList({
  appProps, sortingParams, order, csrfToken, products, productParameters, category, containerClass, content,
  getStoresPath, setDeliveryPath, setPickupPath, currentUser, newAddressPath, addressFormFields, googleApiKey, userCoordinates
}) {
  const [productParams, setProductParams]     = useState(productParameters)
  const [currentProduct, setCurrentProduct]   = useState({})
  const [burgerIsOpened, setBurgerIsOpened]   = useState(false)
  const [toggleFilters, setToggleFilters]     = useState(false)
  const [isMobile, setIsMobile]               = useState(false)
  const [toggleFiltersSidebar, setToggleFiltersSidebar] = useState(false)
  const [toggleMobileSidebar, setToggleMobileSidebar]   = useState(false)
  const showCategories = appProps.categoriesPosition === 'list' || appProps.categoriesPosition === 'header_and_list'

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    loadProductListAnalytics(products, appProps)
  }, [])

  useEffect(() => {
    if (appProps.currentDevice != 'desktop') {
      setIsMobile(true)
      setToggleMobileSidebar(false)
    }
  }, []);

  function toggleBurger(openStatus, currentProduct = {}) {
    setBurgerIsOpened(openStatus)
    setCurrentProduct(currentProduct)
	}

  function escFunction(event) {
    if(event.keyCode === 27) toggleBurger(false)
  }

  function renderBurger(product) {
    if(!burgerIsOpened) {
      return null
    }

    return (
      <div id="products_modal">
        <div className="product-modal-container">
          <div className="product-modal-inner">
            <div id="close_burger" className="close_icon" onClick={() => toggleBurger(false)}></div>
            <div className="single-product-burger">
              <div className="product-section">
                <ProductBurger
                  appProps={ appProps }
                  product={ currentProduct }
                  csrfToken={ csrfToken }
                  toggleBurger={ toggleBurger }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      { !appProps.showChooseMethodModal &&
        <>
          <ListStyles
            appProps={ appProps }
          />
          <div className="main-container">
            <Sorting
              appProps={ appProps }
              productParams={ productParams }
            />

            <div className="product-list-wrapper flex-box flex-wrap">
              { showCategories && appProps.categories.length >= 1 &&
                <>
                  { appProps.currentDevice != 'desktop' &&
                    <div className='toggle-mobile-sidebar-wrapper flex-box content-center items-center'>
                      <div className='toggle-button' onClick={ ()=> (setToggleMobileSidebar(prevState => !prevState), setIsMobile(false)) }>
                        { appProps.translations.general.sidebar_categories_title }
                      </div>
                    </div>
                  }
                  <div className={`sidebar-wrapper ${isMobile ? 'closed' : ''} ${toggleMobileSidebar ? 'mobile open' : '' } ${toggleFiltersSidebar ? 'open' : ''}` }>
                    <div className="sidebar-container">
                      <>
                        { appProps.currentDevice != 'desktop' &&
                          <div className='close_icon pointer' onClick={ ()=> (setToggleMobileSidebar(prevState => !prevState), setIsMobile(true)) } />
                        }
                        <div className="categories_wrapper">
                          <SideCategories
                            appProps={ appProps }
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </>
              }

              <div className="product-list flex-box flex-column">
                { category && content &&
                  <Content
                    appProps={ appProps }
                    getBuilderContentUrl={ content }
                  />
                }
                <div className={`products flex-box flex-wrap ${appProps.listLayout}`}>
                  { products.map((product, index) => (
                    <div key={index} className={`product ${ appProps.productsPerListLine === 1 ? 'list-view' : '' } ${ appProps.productsPerRowClass ? appProps.productsPerRowClass : '' }`}>
                      <ProductItem
                        appProps={ appProps }
                        product={ product }
                        csrfToken={ csrfToken }
                        renderBurger={ renderBurger }
                        toggleBurger={ toggleBurger }
                        burgerIsOpened={ burgerIsOpened }
                      />
                    </div>
                  ))}
                </div>

                <div className="page-navigation-wrapper">
                  <Pagination
                    appProps={ appProps }
                    productParams={ productParams }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      }

      { appProps.showChooseMethodModal &&
        <ChooseMethodModal
          appProps={ appProps }
          getStoresPath={ getStoresPath }
          newAddressPath={ newAddressPath }
          user={ currentUser }
          order={ order }
          addressFormFields={ addressFormFields }
          googleApiKey={ googleApiKey }
          userCoordinates={ userCoordinates }
          setDeliveryPath={ setDeliveryPath }
          setPickupPath={ setPickupPath }
        />
      }

      { renderBurger() }

    </>
  )
}
