import React from "react"

export default function Button({ value, disabled, className, linkTo, onClick, buttonType }) {
  return (
  	<>
      { linkTo ? <a href={linkTo} className={className} onClick={ onClick }>{value}</a> :
        <button type={buttonType || 'submit'} disabled={disabled} className={ className} onClick={ onClick }>{value}</button>
      }
    </>
  )
}
