import React, { useState, useEffect, useCallback } from "react"
import ContentLoader, { Facebook } from "react-content-loader"
import RecentOrders from './RecentOrders'

import { initiateCheckoutAnalytics } from './../../helpers/analytics'

export default function MiniCart({ appProps, order }) {
  const [openCart, setOpenCart]         = useState(false)
  const [currentOrder, setCurrentOrder] = useState(order)
  const [recentOrders, setRecentOrders] = useState([])
  const [populateCartModal, setPopulateCartModal] = useState(false)
  const [lineItems, setLineItems]       = useState([])
  const [loading, setLoading]           = useState(false)

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (openCart) {
      fetchRecentOrders()
    }
  }, [openCart])

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpenCart(false)
    }
  }, []);

  function fetchOrder() {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: `${appProps.fetchOrderPath}?id=${order.id}`,
      dataType: "json",
      success: res => {
        setCurrentOrder(res.order)
        setLineItems(res.lineItems)
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function fetchRecentOrders() {
    Rails.ajax({
      type: "GET",
      url: `${appProps.fetchRecentOrdersPath}`,
      dataType: "json",
      success: res => {
        setRecentOrders(res.orders)
      }
    })
  }

  function deleteItem(lineItemId) {
    setLoading(true)

    Rails.ajax({
      url: `/line_items/${ lineItemId }/delete`,
      type: 'POST',
      dataType: 'json',
      success: res => {
        if (res.isEmpty) {
          window.location.reload()
        } else {
          setCurrentOrder(res.order)
          setLineItems(res.order.line_items)
        }
        setLoading(false)
      }
    })
  }

  return (
    <>
      <a className="pointer cart_icon cart-drop" onClick={(e) => {
        e.preventDefault()
        fetchOrder()
        setOpenCart(prevState => !prevState)
      }}>
        { order && order.line_items.length > 0 &&
          <div className="count">
            { order.line_items.length }
          </div>
        }
      </a>

      { openCart &&
        <>
          <div className={`cart-menu-overlay ${appProps.miniCartType}`} onClick={ ()=> setOpenCart(prevState => !prevState) } />
          <div className={`cart-menu ${appProps.miniCartType}`} style={{ top: appProps.headerHeight }}>
            { appProps.miniCartType == 'burger_opener' &&
              <div onClick={ ()=> setOpenCart(false) } className='pointer close_icon flex-box items-center content-center'/>
            }
            <div className='title'>
              <b>{ appProps.translations.cart.cart_totals }</b>
            </div>

            <RecentOrders
              appProps={ appProps }
              setCurrentOrder={ (updatedOrder)=> setCurrentOrder(updatedOrder) }
              fetchUpdatedOrder={ ()=> fetchOrder() }
            />

          { loading ?
              <ContentLoader>
                <rect x="10" y="27"  rx="10" ry="10" width="100" height="19" />
                <rect x="10" y="57"  rx="17.5" ry="17.5" width="100" height="32" />
                <rect x="10" y="105" rx="10" ry="10" width="100" height="19" />
              </ContentLoader>
            :
              <>
                <div className='line-items'>
                  {
                    lineItems.map((lineItem, index) => {
                      return (
                        <React.Fragment key={ index }>
                          <div className='mini-cart-product flex-box content-space-between' style={{ marginTop: 15 }}>
                            <div className='flex-box'>
                              <div className='product-title'>
                                { lineItem.bundle ? lineItem.bundle.presentation : lineItem.variants[0].product.presentation }
                                { lineItem.quantity > 1 && ` (x${lineItem.quantity})` }
                              </div>
                              <div className='product-price'>
                                { lineItem.total }
                              </div>
                            </div>
                            <div>
                            </div>
                            { lineItem.origin !== 'automation' &&
                              <div className="row actions">
                                <div className="view">
                                  <input type="hidden" name="authenticity_token" value={ appProps.csrfToken } />
                                  <i className="remove_icon" onClick={ () => deleteItem(lineItem.id) }></i>
                                </div>
                              </div>
                            }
                          </div>

                          { lineItem.bundle && lineItem.variants.map((variant, vIndex) => {
                            return (
                              <div key={ vIndex } className='line-item meal-title' style={{ marginTop: 5, paddingLeft: 5 }}>
                                { `${ variant.product.presentation }` }
                              </div>
                            )
                          })}

                          { lineItem.variants && lineItem.variants.map((variant, vIndex) => {
                            return (
                              <div key={ vIndex } className='line-item' style={ lineItem.bundle ? { marginTop: 5, fontSize: 12, paddingLeft: 5 } : { marginTop: 5, fontSize: 12, paddingLeft: 0 }}>
                                { `${ variant.selected_options_texts }` }
                              </div>
                            )
                          })}
                        </React.Fragment>
                      )
                    })
                  }
                </div>
                <div className='totals flex-box flex-column'>
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.cart.subtotal }</div>
                    <div>{ currentOrder ? currentOrder.subtotal : '' }</div>
                  </div>
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.cart.vat }</div>
                    <div>{ currentOrder ? currentOrder.vat_total : '' }</div>
                  </div>
                  <div className='flex-box items-center content-space-between'>
                    <div><b>{ appProps.translations.cart.total }</b></div>
                    <div><b>{ currentOrder ? currentOrder.totalWithAdjustments : '' }</b></div>
                  </div>
                </div>
              </>
            }
            <div className='button-wrapper'>
              <button className='button' onClick={ (e) =>
                  (window.location.href = appProps.checkoutPath,
                  initiateCheckoutAnalytics(currentOrder, e))
              }>
                { appProps.translations.checkout.checkout_button }
              </button>
            </div>
          </div>
        </>
      }
    </>
  )
}
