import React, { useState, useEffect } from "react"

export default function ChooseOrderingMethod({ appProps, orderingMethod }) {
  useEffect(() => {
    const { messages, flashMessage, setCurrentAppProps } = appProps

    if (flashMessage && messages && messages.length > 0) {
      messages.forEach(message => {
        flashMessage.show(message, 'error')
      })
      setCurrentAppProps({ messages: [] })
    }
  }, [appProps])

  return (
    <>
      { appProps.controllerName === 'products' &&
        <div style={{ backgroundColor: '#ffffff' }}>
          { !!appProps.orderingMethod &&
            <div className={`main-container ${appProps.frontendStyles.general.containerWidth != '100%' ? 'skip-padding' : ''}`}>
              <div
                className="flex-box items-center content-end"
                style={{ padding: '5px 0px', fontSize: 11 }}
              >
                <div className="flex-box pointer" onClick={ () => appProps.setCurrentAppProps({ showChooseMethodModal: true }) }>
                  <div className="flex-box flex-column items-end">
                    <div className='flex-box items-center'>
                      <i className={ `fa-light ${ appProps.orderingMethod === 'pickup' ? 'fa-store' : 'fa-map-location' }` } style={{ marginRight: 5 }}/>
                      <div>{ appProps.translations.checkout[`${ appProps.orderingMethod }_title`] }</div>
                    </div>
                    <div className='flex-box items-center' style={{ fontWeight: 600 }}>
                      <div>{ appProps.orderingMethod === 'pickup' ? appProps.currentStore.presentation : appProps.selectedAddress.preview }</div>
                      <i className='fa-light fa-pen-to-square' style={{ marginLeft: 5 }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}
