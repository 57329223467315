import React from "react"
import Button from "../Button"
import LineItem from "./LineItem"
import { initiateCheckoutAnalytics } from './../../helpers/analytics'

export default class BurgerCart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: this.props.order,
      checkingAutomations: false
    }
  }

  componentDidMount() {
    const element = document.getElementById('burger-cart-order-value')
    const orderEvent = new Event('order-change')
    element.addEventListener('order-change', event => {
      this.setState({ order: JSON.parse(event.target.value) })
      element.value = ''
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.order !== this.props.order) {
      this.setState({ order: this.props.order })
    }

    if (this.props.automationAccess && !prevProps.openBurger && this.props.openBurger) {
      this.checkAutomations()
    }
  }

  onChangeLineItemQuantity(updatedOrder) {
    this.setState({ order: updatedOrder })
    if (this.props.automationAccess) {
      this.checkAutomations()
    }
  }

  onDeleteLineItem(updatedOrder, isEmpty) { // isEmpty not used but needs to exist because of shared functionality
    this.setState({ order: updatedOrder })
    if (this.props.automationAccess) {
      this.checkAutomations()
    }
  }

  checkAutomations() {
    this.setState({ checkingAutomations: true })

    Rails.ajax({
      type: 'POST',
      url: this.props.automationsPath,
      dataType: 'json',
      success: res => {
        if (res.order) {
          this.setState({ order: res.order, checkingAutomations: false })
        }
      },
      error: res => {}
    })
  }

  render () {
    const { csrfToken, disableRemove, translations, facebookPixelId, googleAnalyticsId, automationsPath, openBurger, setOpenBurger } = this.props
    const { order } = this.state

    return (
      <React.Fragment>
        { order && !window.location.pathname.includes('checkout') &&
          <div id="burger-cart" className={ `side-burger animated ${ openBurger ? 'open' : 'close' }` }>
            <div className="burger-cart-inner">
              <div id="close-cart-burger" className="close_icon"
                onClick={ () => setOpenBurger(false) }>
              </div>
              <div className="burger-cart-header">
                { translations.cart.items }
              </div>

              <div className="cart-items">
                { order.line_items.map((lineItem, index) =>
                  <LineItem
                    lineItem={lineItem}
                    csrfToken={csrfToken}
                    key={index}
                    disableRemove={disableRemove}
                    translations={translations}
                    location="dropdown-cart"
                    translations={ translations }
                    facebookPixelId={ facebookPixelId }
                    googleAnalyticsId={ googleAnalyticsId }
                    onChangeQuantity={ this.onChangeLineItemQuantity.bind(this) }
                    onDelete={ this.onDeleteLineItem.bind(this) }
                    automationsPath={ automationsPath }
                  />
                )}
              </div>

              <div className="burger-cart-info">
                <h5>{ translations.cart.cart_totals }</h5>
                <h5>{ order.total }</h5>
              </div>

              { this.state.checkingAutomations &&
                <div className='automations-check-wrapper burger-cart'>
                  { Store.translations.checking_automations }
                  <div className='spinner-wrapper'>
                    <div className='spinner user-auth-button'>
                      <div className='double-bounce1'/>
                      <div className='double-bounce2'/>
                    </div>
                  </div>
                </div>
              }

              <div className="burger-cart-footer">
                <Button
                  value={ translations.checkout.checkout_button }
                  className={"button"}
                  linkTo={'/checkout'}
                  onClick={() => { initiateCheckoutAnalytics(order, event) }}
                />
              </div>
            </div>
          </div>
        }

        <input type='hidden' id={ 'burger-cart-order-value' }/>
      </React.Fragment>
    )
  }
}
